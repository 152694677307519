import { useAuthenticationContext, Children } from "design-system";
import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { constant, pipe } from "fp-ts/function";

import { IdleGuard } from "../Common/IdleGuard/IdleGuard";
import { IdleWarningDialog } from "./IdleWarningDialog";

type Props = {
  children: Children;
  logoutHandler: Option<() => void>;
};

export function IdleLogout(props: Props) {
  const DEFAULT_MIN_BEFORE_IDLE = 15;
  const DEFAULT_MIN_WARN_DURATION = 2;

  const { logout } = useAuthenticationContext();

  const logoutHandler = pipe(
    props.logoutHandler,
    option.getOrElse(constant(logout))
  );

  return (
    <IdleGuard
      minutesBeforeIdle={DEFAULT_MIN_BEFORE_IDLE}
      minutesWarningDuration={DEFAULT_MIN_WARN_DURATION}
      onIdle={logoutHandler}
      renderWarning={(dismiss, remainingTime) => (
        <IdleWarningDialog
          remainingTime={remainingTime}
          logout={logoutHandler}
          dismiss={dismiss}
        />
      )}
    >
      {props.children}
    </IdleGuard>
  );
}
