import { Body, buttonLink, link } from "design-system";
import { useFormatMessage } from "../intl";
import { useAppContext } from "../useAppContext";

type Props = {
  onClick?: () => unknown;
};

export function ForgotPasswordLink(props: Props) {
  const formatMessage = useFormatMessage();
  const {
    config: { settingsPageURL },
  } = useAppContext();
  return (
    <Body size="small" weight="regular">
      {[
        formatMessage("Login.forgotPasswordText"),
        props.onClick
          ? buttonLink(props.onClick, formatMessage("Login.forgotPasswordLink"))
          : link(settingsPageURL, formatMessage("Login.forgotPasswordLink")),
      ]}
    </Body>
  );
}
