import { option } from "fp-ts";
import { useFormatMessage } from "../intl";
import { ClockIcon, Dialog } from "design-system";

type Props = {
  remainingTime: number;
  logout: () => void;
  dismiss: () => void;
};

export function IdleWarningDialog(props: Props) {
  const formatMessage = useFormatMessage();
  return (
    <Dialog
      variant="center"
      size="medium"
      onDismiss={option.none}
      icon={ClockIcon}
      title={formatMessage("Login.inactivityModal.title", {
        minutes: Math.floor(props.remainingTime / 60),
        seconds: props.remainingTime % 60,
      })}
      subtitle={formatMessage("Login.inactivityModal.description")}
      actions={[
        {
          action: props.logout,
          label: formatMessage("Logout"),
          variant: "secondary",
        },
        {
          action: props.dismiss,
          label: formatMessage("Continue"),
          variant: "primary",
        },
      ]}
    />
  );
}
