import * as t from "io-ts";
import { eq } from "fp-ts";
import { apiCall } from "../APICall";
import { Eq } from "fp-ts/Eq";
import { KYCAnswersSubmit } from "./domain";
import { constTrue } from "fp-ts/function";
import { LocalizedString } from "design-system";

const SubmitKYCInput = t.type({
  answers: KYCAnswersSubmit,
});
type SubmitKYCAnswersInput = t.TypeOf<typeof SubmitKYCInput>;

const eqSubmitKYCInput: Eq<SubmitKYCAnswersInput> = eq.getStructEq({
  answers: eq.eqStrict,
});

const SubmitKYCErrorId = t.keyof({
  ClientNumberNotReserved: true,
  RiskyClient: true,
});

const SubmitKYCError = t.type(
  {
    id: SubmitKYCErrorId,
    message: LocalizedString,
  },
  "SubmitKYCError"
);
export type SubmitKYCError = t.TypeOf<typeof SubmitKYCError>;

const SubmitKYCOutput = t.type(
  {
    idConfirmedRemote: t.boolean,
  },
  "SubmitKYCOutput"
);
export type SubmitKYCOutput = t.TypeOf<typeof SubmitKYCOutput>;

export const submitKYC = apiCall({
  inputEq: eqSubmitKYCInput,
  path: ["packages", "uKonto", "knowYourCustomer", "submit"],
  inputCodec: SubmitKYCInput,
  errorCodec: SubmitKYCError,
  outputCodec: SubmitKYCOutput,
});

export const Country = t.type(
  {
    countryName: LocalizedString,
    countryCode: t.string,
    TIN: t.boolean,
  },
  "Country"
);

export type Country = t.TypeOf<typeof Country>;

export const countries = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["clients", "knowYourCustomer", "countries"],
  inputCodec: t.void,
  outputCodec: t.array(Country, "KYCCountries"),
});

export const embargoedCountries = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["clients", "knowYourCustomer", "embargoedHighRiskCountries"],
  inputCodec: t.void,
  outputCodec: t.array(Country, "EmbargoedCountries"),
});
