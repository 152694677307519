import * as t from "io-ts";
import { LocalizedString, NonNegative } from "design-system";
import { optionFromUndefined } from "../globalDomain";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { AllCitizenships } from "../IdUpload/domain";

export const KYCVariant = t.keyof({
  UKonto: true,
  LegalData: true,
});
export type KYCVariant = t.TypeOf<typeof KYCVariant>;

export const IncomeSource = t.keyof({
  Employed: true,
  Entrepreneur: true,
  Freelancer: true,
  Pensioner: true,
  MaternityLeave: true,
  CompanyOwner: true,
  HomeDuties: true,
  Unemployed: true,
  Student: true,
  Other: true,
});
export type IncomeSource = t.TypeOf<typeof IncomeSource>;

export const Industry = t.keyof({
  AccomodationAndFoodServiceActivities: true,
  ActivitiesOfExtraTerritorialOrganizationsAndBodies: true,
  ActivitiesOfHouseholds: true,
  AdministrativeAndSupportServiceActivities: true,
  AgricultureForestryFishing: true,
  ArtsEntertainmentAndRecreation: true,
  Construction: true,
  Education: true,
  ElectricityGasSteamAndAirConditioningSupply: true,
  FinancialAndInsuranceActivities: true,
  HealthAndSocialCare: true,
  InformationAndCommunicationActivities: true,
  ManufacturingIndustry: true,
  MiningAndQuarrying: true,
  ProfessionalScientificAndTechnicalActivities: true,
  PublicAdministrationAndDefense: true,
  CompulsorySocialSecurity: true,
  RealEstateActivities: true,
  TransportAndStorage: true,
  WaterSupply: true,
  WasteAndRemediationActivities: true,
  WholeAndRetailTrade: true,
  RepairAndMaintenanceServicesOfMotorVehicles: true,
  Other: true,
});
export type Industry = t.TypeOf<typeof Industry>;

export const incomeSourcesWithIndustry: Array<string> = [
  "Employed",
  "Entrepreneur",
  "Freelancer",
  "CompanyOwner",
];

export const incomeSourcesWithIndustryMTG: Array<string> = [
  "Employed",
  "Freelancer",
  "EmployedAbroad",
  "CompanyOwner",
  "TradesmanCoOperatingPerson",
];

export const IncomeInfo = t.type({
  monthlyIncome: optionFromUndefined(NonNegative),
  incomeSource: optionFromUndefined(IncomeSource),
  incomeSourceLabel: optionFromUndefined(LocalizedString),
  industry: optionFromUndefined(Industry),
  industryLabel: optionFromUndefined(LocalizedString),
  otherIndustry: optionFromUndefined(t.string),
  employerName: optionFromUndefined(t.string),
  companyICO: optionFromUndefined(t.string),
  riskyActivities: optionFromUndefined(t.boolean),
});
export type IncomeInfo = t.TypeOf<typeof IncomeInfo>;

export const PaymentDestination = t.keyof({
  Domestic: true,
  ForeignEU: true,
  OutsideEU: true,
});
export type PaymentDestination = t.TypeOf<typeof PaymentDestination>;

export const PaymentDestination2 = t.keyof({
  DomesticAndEU: true,
  OutsideEU: true,
});
export type PaymentDestination2 = t.TypeOf<typeof PaymentDestination2>;

export const TransactionType = t.keyof({
  CashAndCashless: true,
  Cashless: true,
  Cash: true,
});
export type TransactionType = t.TypeOf<typeof TransactionType>;

export const NegativeReputationType = t.keyof({
  FinancialCrime: true,
  Corruption: true,
  ViolenceBrutalityBlackmail: true,
  OrganizedCrime: true,
  Other: true,
  Fraud: true,
  SexOffence: true,
  StatusW4: true,
});
export type NegativeReputationType = t.TypeOf<typeof NegativeReputationType>;
export const SourceOfWealth = t.keyof({
  Investment: true,
  Other: true,
  PropertySale: true,
  Divorce: true,
  Business: true,
  Gambling: true,
  Employment: true,
});
export type SourceOfWealth = t.TypeOf<typeof SourceOfWealth>;
export const PaymentRelation = t.keyof({
  MostlyForeign: true,
  ForeignAndDomestic: true,
});

export type PaymentRelation = t.TypeOf<typeof PaymentRelation>;
export const PaymentMethod = t.keyof({
  MostlyNotCash: true,
  MostlyCash: true,
});
export type PaymentMethod = t.TypeOf<typeof PaymentMethod>;

export const AverageIncomingPaymentsIdCz = t.keyof({
  CZ_0_50: true,
  CZ_50_100: true,
  CZ_100_250: true,
  CZ_250: true,
});

export type AverageIncomingPaymentsIdCz = t.TypeOf<
  typeof AverageIncomingPaymentsIdCz
>;

export const AverageIncomingPaymentsIdSk = t.keyof({
  SK_0_2: true,
  SK_2_4: true,
  SK_4_6: true,
  SK_6: true,
});

export type AverageIncomingPaymentsIdSk = t.TypeOf<
  typeof AverageIncomingPaymentsIdSk
>;

export const AverageIncomingPaymentsId = t.union([
  AverageIncomingPaymentsIdCz,
  AverageIncomingPaymentsIdSk,
]);

export type AverageIncomingPaymentsId = t.TypeOf<
  typeof AverageIncomingPaymentsId
>;

export const AverageIncomingPayments = t.type({
  id: AverageIncomingPaymentsId,
  value: t.unknown,
});
export type AverageIncomingPayments = t.TypeOf<typeof AverageIncomingPayments>;

export const SourceOfFundsId = t.keyof({
  Salary: true,
  Pension: true,
  GiftOrWin: true,
  SaleEstate: true,
  SaleShares: true,
  Heritage: true,
  Loans: true,
  CompanyProfitDividends: true,
  InsurancePayment: true,
  PersonalSavings: true,
  Other: true,
});
export type SourceOfFundsId = t.TypeOf<typeof SourceOfFundsId>;

export const SourceOfFunds = t.type({
  id: SourceOfFundsId,
  value: t.unknown,
  otherSource: optionFromUndefined(t.string),
});
export type SourceOfFunds = t.TypeOf<typeof SourceOfFunds>;

export const SourceOfIncomeId = t.keyof({
  Employee: true,
  StudentOrKid: true,
  Pensioner: true,
  Rentier: true,
  Entrepreneur: true,
  Unemployed: true,
});
export type SourceOfIncomeId = t.TypeOf<typeof SourceOfIncomeId>;

export const SourceOfIncome = t.type({
  id: SourceOfIncomeId,
  value: t.unknown,
});
export type SourceOfIncome = t.TypeOf<typeof SourceOfIncome>;

export const Country = t.type(
  {
    countryName: LocalizedString,
    countryCode: t.string,
    TIN: t.boolean,
  },
  "Country"
);

export type Country = t.TypeOf<typeof Country>;

//used for new kyc questionnaire, under FF
export const TransactionsInfo2 = t.type({
  averageMonthlyIncomePayments: optionFromUndefined(AverageIncomingPayments),
  paymentDestination: optionFromUndefined(PaymentDestination2),
  paymentDestinationLabel: optionFromUndefined(LocalizedString),
  paymentDestinationCountries: optionFromUndefined(t.string),
  paymentDestinationCountriesWithLabels: optionFromUndefined(t.array(Country)),
  reasonOfTransactions: optionFromUndefined(t.string),
  sourceOfFunds: optionFromUndefined(SourceOfFunds),
  sourceOfIncome: optionFromUndefined(SourceOfIncome),
  monthlyIncome: optionFromUndefined(t.number),
  employerName: optionFromUndefined(t.string),
  companyICO: optionFromUndefined(t.string),
});
export type TransactionsInfo2 = t.TypeOf<typeof TransactionsInfo2>;

//used for old kyc questionnaire which is under FF + foreigners
export const TransactionsInfo1 = t.intersection([
  t.type({
    paymentDestination: PaymentDestination,
    paymentDestinationLabel: LocalizedString,
    paymentDestinationCountries: optionFromUndefined(t.string),
    transactionType: TransactionType,
    transactionTypeLabel: LocalizedString,
    cashTransactionMotivation: optionFromUndefined(t.string),
  }),
  t.partial({
    localTransactionsYear: t.number,
    foreignTransactionsMonth: t.number,
    cashDepositsMonth: t.number,
    originOfFunds: t.string,
    sourceOfWealthType: SourceOfWealth,
    negativeReputation: t.boolean,
    negativeReputationType: optionFromUndefined(NegativeReputationType),
    sourceOfWealthText: NonEmptyString,
    netWorth: NonEmptyString,
    creditTurnoverYear: t.number,
    paymentMethod: PaymentMethod,
    reasonCash: optionFromUndefined(t.string),
    paymentRelation: PaymentRelation,
  }),
]);
export type TransactionsInfo1 = t.TypeOf<typeof TransactionsInfo1>;

export const TransactionsInfo = t.union([TransactionsInfo1, TransactionsInfo2]);
export type TransactionsInfo = t.TypeOf<typeof TransactionsInfo>;

export const TransactionsInfoCZ = t.type({
  paymentDestination: PaymentDestination,
  paymentDestinationLabel: LocalizedString,
  paymentDestinationCountries: optionFromUndefined(t.string),
  transactionType: TransactionType,
  transactionTypeLabel: LocalizedString,
  cashTransactionMotivation: optionFromUndefined(t.string),
  localTransactionsYear: t.number,
  foreignTransactionsMonth: t.number,
  cashDepositsMonth: t.number,
  originOfFunds: t.string,
  sourceOfWealthType: SourceOfWealth,
  negativeReputation: t.boolean,
  negativeReputationType: optionFromUndefined(NegativeReputationType),
});

export const TransactionsInfoSK = t.type({
  paymentDestination: PaymentDestination,
  paymentDestinationLabel: LocalizedString,
  paymentDestinationCountries: optionFromUndefined(t.string),
  transactionType: TransactionType,
  transactionTypeLabel: LocalizedString,
  cashTransactionMotivation: optionFromUndefined(t.string),
  sourceOfWealthText: NonEmptyString,
  netWorth: NonEmptyString,
  creditTurnoverYear: t.number,
  paymentMethod: PaymentMethod,
  reasonCash: optionFromUndefined(t.string),
  paymentRelation: PaymentRelation,
});
export type TransactionsInfoSK = t.TypeOf<typeof TransactionsInfoSK>;
export type TransactionsInfoCZ = t.TypeOf<typeof TransactionsInfoCZ>;

export const ReasonNote = t.keyof({
  NotIssuedToMe: true,
  DoNotKnow: true,
  RefuseToProvide: true,
});

export type ReasonNote = t.TypeOf<typeof ReasonNote>;

export const TaxPayerCountry = t.type({
  country: t.string,
  TIN: optionFromUndefined(t.string),
  note: optionFromUndefined(ReasonNote),
});
export type TaxPayerCountry = t.TypeOf<typeof TaxPayerCountry>;

export type TaxPayerCountryWithRequiresTIN = TaxPayerCountry & {
  countryRequiresTIN: boolean;
};

export const PersonalAndTaxInfo = t.type({
  politicallyExposedPerson: t.boolean,
  taxPayerOutsideCzechRepublic: t.boolean,
  otherTaxPayerCountries: t.array(TaxPayerCountry),
  usCitizenOrTaxPayer: t.boolean,
  ssn: optionFromUndefined(t.string),
  secondCitizenship: optionFromUndefined(AllCitizenships),
});
export type PersonalAndTaxInfo = t.TypeOf<typeof PersonalAndTaxInfo>;

export const KYCAnswers = t.type({
  incomeInfo: optionFromUndefined(IncomeInfo),
  transactionsInfo: TransactionsInfo,
  personalAndTaxInfo: PersonalAndTaxInfo,
});
export type KYCAnswers = t.TypeOf<typeof KYCAnswers>;

export const KYCAnswers2 = t.type({
  transactionsInfo: TransactionsInfo2,
  personalAndTaxInfo: PersonalAndTaxInfo,
});
export type KYCAnswers2 = t.TypeOf<typeof KYCAnswers2>;

export const KYCAnswersSubmit = t.type({
  incomeInfo: optionFromUndefined(IncomeInfo),
  transactionsInfo: optionFromUndefined(TransactionsInfo),
  personalAndTaxInfo: optionFromUndefined(PersonalAndTaxInfo),
});
export type KYCAnswersSubmit = t.TypeOf<typeof KYCAnswers>;

export const ClientTransactionsInput = t.type({
  averageMonthlyIncomePayments: optionFromUndefined(AverageIncomingPayments),
});
export type ClientTransactionsInput = t.TypeOf<typeof ClientTransactionsInput>;
