import { LocaleKey, useFormatMessage } from "../intl";
import {
  AverageIncomingPaymentsId,
  Industry,
  KYCVariant,
  PaymentDestination,
  PaymentDestination2,
  SourceOfFundsId,
  SourceOfIncomeId,
  TransactionType,
} from "./domain";
import { Option } from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";

export function useFormatIndustry() {
  const formatMessage = useFormatMessage();
  return (industry: Industry) =>
    formatMessage(`KYC.Income.Industry.${industry}` as LocaleKey);
}

export function useFormatPaymentDestination() {
  const formatMessage = useFormatMessage();
  return (paymentDestination: PaymentDestination | PaymentDestination2) =>
    formatMessage(
      `KYC.Transactions.PaymentDestination.${paymentDestination}` as LocaleKey
    );
}

export function useFormatTransactionType() {
  const formatMessage = useFormatMessage();
  return (transactionType: TransactionType) =>
    formatMessage(
      `KYC.Transactions.TransactionType.${transactionType}` as LocaleKey
    );
}

export function useFormatAverageIncomingPayments() {
  const formatMessage = useFormatMessage();
  return (paymentAmount: AverageIncomingPaymentsId) =>
    formatMessage(
      `KYC.Transactions.averageIncomingPayments.${paymentAmount}` as LocaleKey
    );
}

export function useFormatSourceOfFunds() {
  const formatMessage = useFormatMessage();
  return (sourceOfFunds: SourceOfFundsId) =>
    formatMessage(
      `KYC.Transactions.sourceOfFunds.${sourceOfFunds}` as LocaleKey
    );
}

export function useFormatSourceOfIncome() {
  const formatMessage = useFormatMessage();
  return (sourceOfIncome: SourceOfIncomeId) =>
    formatMessage(
      `KYC.Transactions.sourceOfIncome.${sourceOfIncome}` as LocaleKey
    );
}

export function foldKYCVariant<T>(
  variant: KYCVariant,
  match: {
    whenUKonto: () => T;
    whenLegalData: () => T;
  }
): T {
  switch (variant) {
    case "UKonto":
      return match.whenUKonto();
    case "LegalData":
      return match.whenLegalData();
  }
}

export function shouldAskPaymentDestinationCountries(
  paymentDestination: Option<PaymentDestination2>
) {
  return pipe(
    paymentDestination,
    option.exists(paymentDestination => paymentDestination === "OutsideEU")
  );
}

export function shouldAskOtherFundsDetails(
  sourceOfFunds: Option<SourceOfFundsId>
) {
  return pipe(
    sourceOfFunds,
    option.exists(v => v === "Other")
  );
}

export const incomeSourcesWithIndustry2: Array<string> = [
  "Employee",
  "Rentier",
  "Entrepreneur",
];
