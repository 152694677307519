import * as t from "io-ts";
import { eq } from "fp-ts";
import { apiCall } from "../../APICall";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { constFalse } from "fp-ts/function";
import {
  CompressedFileContent,
  MoneyAmount,
  optionFromUndefined,
} from "../../globalDomain";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { ApplicantsRecordC, withApplicantIndex } from "../domain";
import { LocalizedString, PositiveInteger } from "design-system";
import { RuntimeLocaleKey } from "../../intl";
import { SubPropertyType } from "../PropertyAndAppraisal/domain";
import { LoanPurpose } from "../PurposeAndFinancing/domain";
import { IncomeSource } from "../Bonita/Income/domain";
import { DocumentMime } from "../../Common/documentAPI";
import { SealingStatus } from "../../UploadDocuments/domain";

const FilenetDocumentType = t.keyof({
  NA: true,
  PROOF_OF_INCOME: true,
});
const Product = t.keyof({
  MORTGAGE: true,
  CL: true,
  SL: true,
  TL: true,
  RPL: true,
  RL: true,
});
const DocumentSlot = t.keyof({
  First: true,
  Second: true,
});
export type DocumentSlot = t.TypeOf<typeof DocumentSlot>;

const DocumentTab = t.keyof({
  Identity: true,
  Income: true,
  Purpose: true,
  Property: true,
  Others: true,
});
export type DocumentTab = t.TypeOf<typeof DocumentTab>;

const RestoreDeletedDocumentInput = t.type({
  docTypeId: NonEmptyString,
  applicantIndex: optionFromUndefined(t.string),
  applicationElementId: optionFromNullable(t.string),
});

export const restoreDeletedDocument = apiCall({
  inputCodec: RestoreDeletedDocumentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  path: ["packages", "mortgage", "document", "restoreDeleteRequired"],
});

const DeleteRequiredDocumentInput = t.type({
  docTypeId: NonEmptyString,
  applicantIndex: optionFromUndefined(t.string),
  applicationElementId: optionFromNullable(t.string),
});

export const deleteRequiredDocument = apiCall({
  inputCodec: DeleteRequiredDocumentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  path: ["packages", "mortgage", "document", "deleteRequired"],
});

const RemoveDocumentInput = t.type({
  docId: NonEmptyString,
});

export const removeDocument = apiCall({
  inputCodec: RemoveDocumentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  path: ["packages", "mortgage", "document", "remove"],
});

export const removeDocumentByClient = apiCall({
  inputCodec: RemoveDocumentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  path: ["packages", "mortgage", "document", "removeClient"],
});

const documentsModifiedInput = t.type({
  applicantIndex: t.string,
});

const eqDocumentsModifiedInput = eq.getStructEq({
  applicantIndex: eq.eqString,
});

const documentsModifiedOutput = t.type({
  value: t.boolean,
});

export const documentsModified = apiCall({
  inputCodec: documentsModifiedInput,
  inputEq: eqDocumentsModifiedInput,
  outputCodec: documentsModifiedOutput,
  path: ["packages", "mortgage", "document", "check"],
});

const DocumentUploadStatus = t.keyof({
  OK: true,
  TECHNICAL_ERROR: true,
  FILE_SIZE_ERROR: true,
  FORMAT_NOT_SUPPORTED: true,
});
export type DocumentUploadStatus = t.TypeOf<typeof DocumentUploadStatus>;

const NoteUploadStatus = t.keyof({
  OK: true,
  TECHNICAL_ERROR: true,
});

const DocumentConfirmOutput = t.type({
  status: NoteUploadStatus,
});
export type DocumentConfirmOutput = t.TypeOf<typeof DocumentConfirmOutput>;

const DocumentConfirmInput = t.type({
  note: optionFromNullable(t.string),
});

export const documentConfirm = apiCall({
  inputCodec: DocumentConfirmInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: DocumentConfirmOutput,
  path: ["packages", "mortgage", "document", "confirm"],
});

export const proofOfIncomeConfirm = apiCall({
  inputCodec: withApplicantIndex(DocumentConfirmInput),
  inputEq: eq.fromEquals(constFalse),
  outputCodec: DocumentConfirmOutput,
  path: ["clients", "identification", "document", "confirm"],
});

export const documentCancel = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  path: ["packages", "mortgage", "document", "cancel"],
});

export const proofOfIncomeCancel = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  path: ["clients", "identification", "document", "cancel"],
});

const RequiredDocument = t.type({
  applicationElementID: optionFromNullable(NonEmptyString),
  clientID: optionFromNullable(NonEmptyString),
  documentTypeID: NonEmptyString,
  documentTypeName: RuntimeLocaleKey,
  section: t.string,
  translationCode: t.string,
  maxLengthNote: PositiveInteger,
  deletedRequiredDocument: optionFromNullable(t.boolean),
});
export type RequiredDocument = t.TypeOf<typeof RequiredDocument>;

export const UploadedDocument = t.type({
  applicationElementId: optionFromNullable(t.string),
  clientID: optionFromNullable(t.string),
  comment: t.string,
  docHash: t.string,
  docId: NonEmptyString,
  docTitle: RuntimeLocaleKey,
  docType: t.string,
  docTypeId: t.string,
  docTypeName: t.string,
  docset: t.string,
  isReadUpdateTime: t.number,
  mimeType: t.string,
  read: t.boolean,
  sealedDocHash: t.string,
  sealedDocId: t.string,
  section: DocumentTab,
  suffix: t.string,
  timestampAdded: t.number,
  applicantIndex: optionFromNullable(t.string),
});
export type UploadedDocument = t.TypeOf<typeof UploadedDocument>;

export const LiabilityInfo = t.type({
  providerName: LocalizedString,
  typeOfProduct: LocalizedString,
  moneyAmount: MoneyAmount,
});

export const Document = t.type({
  note: optionFromNullable(t.string),
  requiredDocument: RequiredDocument,
  uploadedDocument: optionFromNullable(UploadedDocument),
  exceptionComment: optionFromNullable(t.string),
  additionalInfo: optionFromNullable(LiabilityInfo),
});
export type Document = t.TypeOf<typeof Document>;

export const OptionalDocument = t.type({
  note: optionFromNullable(t.string),
  uploadedDocument: UploadedDocument,
});
export const NewRequiredDocument = t.type({
  note: optionFromNullable(t.string),
  uploadedDocument: UploadedDocument,
});
export type OptionalDocument = t.TypeOf<typeof OptionalDocument>;

const PropertyAdditionalData = t.type({
  type: t.literal("Property"),
  propertyCity: LocalizedString,
  propertyNumber: LocalizedString,
  propertyStreet: LocalizedString,
  propertyType: SubPropertyType,
});
const IncomeAdditionalData = t.type({
  type: t.literal("Income"),
  incomeType: IncomeSource,
  companyName: optionFromNullable(LocalizedString),
});
const PurposeAdditionalData = t.type({
  type: t.literal("Purpose"),
  purposeType: LoanPurpose,
});

const AllAdditionalData = t.union([
  PropertyAdditionalData,
  IncomeAdditionalData,
  PurposeAdditionalData,
]);
export type AllAdditionalData = t.TypeOf<typeof AllAdditionalData>;

const SectionData = t.type({
  additionalData: optionFromNullable(AllAdditionalData),
  sectionDocuments: optionFromNullable(t.array(Document)),
  sectionName: optionFromNullable(RuntimeLocaleKey),
  sectionElementId: optionFromNullable(t.string),
});

export type SectionData = t.TypeOf<typeof SectionData>;

const ExtendedSectionData = t.intersection([
  SectionData,
  t.type({
    subpropertyList: t.array(SectionData),
    appraisalSection: optionFromNullable(SectionData),
  }),
]);
export type ExtendedSectionData = t.TypeOf<typeof ExtendedSectionData>;

const ProceedWithDocumentsOutput = t.type({
  optionalDocuments: t.array(OptionalDocument),
  applicantDocumentSections: ApplicantsRecordC(
    t.type({
      identityDocumentsSection: optionFromNullable(SectionData),
      incomeDocumentsSections: t.array(SectionData),
    })
  ),
  allowDeleteRequiredDoc: optionFromNullable(t.boolean),
  commonDocumentsSection: t.type({
    otherDocumentsSections: optionFromNullable(SectionData),
    propertyDocumentsSections: t.array(ExtendedSectionData),
    purposeDocumentsSections: t.array(SectionData),
  }),
});
export type ProceedWithDocumentsOutput = t.TypeOf<
  typeof ProceedWithDocumentsOutput
>;

export const proceedWithDocuments = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ProceedWithDocumentsOutput,
  path: ["packages", "mortgage", "document", "proceedWithDocuments"],
});

export const requestRequiredDocuments = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  path: ["packages", "mortgage", "document", "requestRequiredDocuments"],
});

const RequiredDocumentsStatusOutput = t.type({
  requiredDocumentsStatus: t.keyof({
    Complete: true,
    Error: true,
    ErrorInvalid: true,
    InProgress: true,
    Timeout: true,
    Todo: true,
  }),
});

export const requiredDocumentsStatus = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: RequiredDocumentsStatusOutput,
  path: ["packages", "mortgage", "document", "requiredDocumentsStatus"],
});

const UploadDocumentInput = t.type({
  documentContent: CompressedFileContent,
  fileName: t.string,
  documentTypeID: NonEmptyString,
  applicationNumber: t.string,
  mimeType: DocumentMime,
  applicantIndex: optionFromNullable(t.string),
});

const UploadRequiredDocumentInput = t.intersection([
  UploadDocumentInput,
  t.type({
    sectionElementId: optionFromNullable(t.string),
  }),
]);

const UploadClientRequiredDocumentInput = t.intersection([
  UploadDocumentInput,
  t.type({
    sectionElementId: optionFromNullable(t.string),
    uploaderApplicantIndex: t.string,
  }),
]);

export const UploadDocumentOutput = t.type({
  status: DocumentUploadStatus,
});
export type UploadDocumentOutput = t.TypeOf<typeof UploadDocumentOutput>;

export const ConfirmDocumentUploadOutput = t.type({
  status: NoteUploadStatus,
});
export type ConfirmDocumentUploadOutput = t.TypeOf<
  typeof ConfirmDocumentUploadOutput
>;

export const uploadRequiredDocument = apiCall({
  inputCodec: UploadRequiredDocumentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: UploadDocumentOutput,
  path: ["packages", "mortgage", "document", "uploadRequiredDocument"],
});

export const uploadClientRequiredDocument = apiCall({
  inputCodec: UploadClientRequiredDocumentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: UploadDocumentOutput,
  path: ["packages", "mortgage", "document", "uploadClientRequiredDocument"],
});

const UploadProofOfIncomeDocumentInput = t.type({
  applicantIndex: t.string,
  filenetDocumentType: FilenetDocumentType,
  product: Product,
  fileContent: CompressedFileContent,
  docTypeId: NonEmptyString,
  applicationElementID: optionFromUndefined(NonEmptyString),
});

export const uploadProofOfIncomeDocument = apiCall({
  path: ["clients", "identification", "document", "upload"],
  inputCodec: UploadProofOfIncomeDocumentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: UploadDocumentOutput,
});

export const uploadProofOfIncomeDocumentByClient = apiCall({
  path: ["clients", "identification", "document", "uploadClient"],
  inputCodec: UploadProofOfIncomeDocumentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: UploadDocumentOutput,
});

const UploadProofOfIncomeDocumentsMobileInput = t.type({
  product: Product,
  docTypeId: NonEmptyString,
  applicationElementID: NonEmptyString,
  filenetDocumentType: FilenetDocumentType,
  firstPage: t.type({
    content: CompressedFileContent,
    mimeType: DocumentMime,
  }),
  sameDevice: t.boolean,
});

export const uploadProofOfIncomeDocumentMobile = apiCall({
  path: ["clients", "identification", "mobileUpload", "document"],
  inputCodec: UploadProofOfIncomeDocumentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: UploadDocumentOutput,
});

export const proofOfIncomeMortgageComplete = apiCall({
  path: ["packages", "mortgage", "document", "complete"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

export const uploadPOIByClient = apiCall({
  path: ["packages", "loans", "standard-loan", "document", "uploadPOIByClient"],
  inputCodec: UploadProofOfIncomeDocumentsMobileInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: UploadDocumentOutput,
});

export const proofOfIncomeCfComplete = apiCall({
  path: ["clients", "identification", "mobileUpload", "cf", "complete"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

export const proofOfIncomeMortgageCompleteMobile = apiCall({
  path: ["clients", "identification", "mobileUpload", "complete"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

const UploadOptionalDocumentInput = t.intersection([
  UploadDocumentInput,
  t.type({
    section: t.keyof({
      Income: true,
      Purpose: true,
      Property: true,
      Identity: true,
      Others: true,
      NewRequiredDocuments: true,
    }),
  }),
]);

const UploadClientOptionalDocumentInput = t.intersection([
  UploadDocumentInput,
  t.type({
    uploaderApplicantIndex: t.string,
    section: t.keyof({
      Income: true,
      Purpose: true,
      Property: true,
      Identity: true,
      Others: true,
      NewRequiredDocuments: true,
    }),
  }),
]);

const UploadOptionalDocumentOutput = t.intersection([
  UploadDocumentOutput,
  t.type({
    maxLengthNote: PositiveInteger,
  }),
]);

export const uploadOptionalDocument = apiCall({
  inputCodec: UploadOptionalDocumentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: UploadOptionalDocumentOutput,
  path: ["packages", "mortgage", "document", "uploadOptionalDocument"],
});

export const uploadClientOptionalDocument = apiCall({
  inputCodec: UploadClientOptionalDocumentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: UploadOptionalDocumentOutput,
  path: ["packages", "mortgage", "document", "uploadClientOptionalDocument"],
});

export const exitDocumentsSection = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  path: ["packages", "mortgage", "document", "exit"],
});

const SkipOrEditDocumentInput = t.type({
  docTypeId: NonEmptyString,
  comment: t.string,
  applicantIndex: optionFromUndefined(t.string),
});

export const skipDocument = apiCall({
  inputCodec: SkipOrEditDocumentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  path: ["packages", "mortgage", "document", "skipDocument"],
});

export const editDocumentComment = apiCall({
  inputCodec: SkipOrEditDocumentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  path: ["packages", "mortgage", "document", "editDocumentComment"],
});

const sendEmailInput = t.type({
  applicantIndex: t.string,
  notes: optionFromUndefined(NonEmptyString),
});

export const sendEmail = apiCall({
  inputCodec: sendEmailInput,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "email", "client"],
});

const ClientUploadLinkStatusOutput = t.type({
  status: t.keyof({
    SENT: true,
    NOT_SENT: true,
    APP_SENT_TO_BANK: true,
  }),
  applicantIndex: optionFromUndefined(t.string),
});

export const clientUploadLinkStatus = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "document", "clientUploadLinkStatus"],
  outputCodec: ClientUploadLinkStatusOutput,
});

const ProofOfIncomeSealingStatusInput = t.type({
  docTypeId: NonEmptyString,
  appElementId: NonEmptyString,
});

const ProofOfIncomeSealingStatusOutput = t.type({ status: SealingStatus });

export const ProofOfIncomeSealingStatus = apiCall({
  path: ["packages", "loans", "standard-loan", "document", "getSealingStatus"],
  inputCodec: ProofOfIncomeSealingStatusInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ProofOfIncomeSealingStatusOutput,
});
