import * as t from "io-ts";
import { UUID } from "io-ts-types/lib/UUID";
import {
  NonNegative,
  Positive,
  PositiveInteger,
  NonNegativeInteger,
  LocalizedString,
} from "design-system";
import {
  DateFromSQLString,
  eqMoneyAmount,
  MoneyAmount,
  optionFromArray,
  optionFromUndefined,
  PercentageLikeFromNumber,
} from "../../globalDomain";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { ApplicantIndex } from "../domain";
import { nonEmptyArray } from "io-ts-types/lib/nonEmptyArray";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";
import { Eq } from "fp-ts/Eq";
import { Option } from "fp-ts/Option";
import { eq, option } from "fp-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { RuntimeLocaleKey } from "../../intl";

export const LoanTypeCommon = t.keyof(
  {
    Purpose: true,
    NonPurpose: true,
  },
  "LoanType"
);

export const LoanTypeCombined = t.literal("Combined");

export const LoanType = t.union([LoanTypeCommon, LoanTypeCombined], "LoanType");
export type LoanType = t.TypeOf<typeof LoanType>;

export const Fees = t.type(
  {
    processingFee: MoneyAmount,
    appraisalFee: optionFromNullable(MoneyAmount),
    propertyInsuranceFee: optionFromNullable(MoneyAmount),
    appraisalFeeEditable: optionFromUndefined(t.boolean),
    propertyInsuranceFeeEditable: optionFromUndefined(t.boolean),
    appraisalFeeTooltip: optionFromUndefined(t.boolean),
    appraisalFeeMessage: optionFromUndefined(t.boolean),
    propertyInsuranceFeeMessage: optionFromUndefined(t.boolean),
    propertyInsuranceFeeTooltip: optionFromUndefined(t.boolean),
  },
  "Fees"
);
export type Fees = t.TypeOf<typeof Fees>;

export const DiscountOf = t.keyof({
  INTEREST_RATE: true,
  FEE_PROCESS: true,
  FEE_APPRAISAL: true,
  GREEN_MORTGAGE_APPR: true,
  GREEN_MORTGAGE_PROC: true,
  CPI_DISCOUNT_ON_PROCESSING_FEE: true,
  REFINANCING_DISCOUNT_ON_PROCESSING_FEE: true,
  APPRAISAL_BENEFIT: true,
});
export type DiscountOf = t.TypeOf<typeof DiscountOf>;

const DiscountParameters = t.type(
  {
    discountType: DiscountOf,
    discountProcessType: t.string,
    discountReason: optionFromUndefined(t.string),
    discountComment: optionFromUndefined(t.string),
    disabledForSelection: t.boolean,
    discountValueFeeMarketing: optionFromUndefined(t.number),
    discountValueFeeManagement: optionFromUndefined(t.number),
    appraisalFeeMKTDiscountPercentage: optionFromUndefined(t.number),
    appraisalFeeMNGDiscountPercentage: optionFromUndefined(t.number),
    discountIRMarketing: optionFromUndefined(t.number),
    discountIRManagement: optionFromUndefined(t.number),
  },
  "DiscountParameters"
);
export type DiscountParameters = t.TypeOf<typeof DiscountParameters>;

export const Percentage = t.keyof({
  OneHundred: true,
  SeventyFive: true,
  Fifty: true,
  TwentyFive: true,
});

export const TableState = t.keyof({
  loading: true,
  error: true,
  success: true,
});
export type TableState = t.TypeOf<typeof TableState>;

export type Percentage = t.TypeOf<typeof Percentage>;

export const eqCustomFees: Eq<Option<Fees>> = option.getEq(
  eq.getStructEq({
    processingFee: eqMoneyAmount,
    appraisalFee: option.getEq(eqMoneyAmount),
  })
);

export type CPITypeBrand = NonEmptyString & {
  readonly CPIType: unique symbol;
};

export const CpiType = t.brand(
  NonEmptyString,
  (cpiType): cpiType is t.Branded<NonEmptyString, CPITypeBrand> =>
    cpiType !== "No",
  "CPIType"
);
export type CpiType = t.TypeOf<typeof CpiType>;

export const CpiTypeNone = t.literal("No", "CpiTypeNone");
export type CpiTypeNone = t.TypeOf<typeof CpiTypeNone>;

export const InsuranceType = t.union([CpiType, CpiTypeNone], "InsuranceType");
export type InsuranceType = t.TypeOf<typeof InsuranceType>;

const TenorType = t.keyof(
  {
    Years: true,
    Months: true,
  },
  "TenorType"
);

const Tenor = t.type(
  {
    tenorValue: NonNegativeInteger,
    tenorType: TenorType,
  },
  "Tenor"
);

export type Tenor = t.TypeOf<typeof Tenor>;

export const eqTenor: Eq<Tenor> = eq.getStructEq({
  tenorValue: eq.eqNumber,
  tenorType: eq.eqString,
});

const TenorLimits = t.type({
  tenorMin: NonNegativeInteger,
  tenorMax: NonNegativeInteger,
  tenorMaxMain: optionFromUndefined(NonNegativeInteger),
  tenorMaxCoApplicant: optionFromUndefined(NonNegativeInteger),
  stepSize: Positive,
});

export type TenorLimits = t.TypeOf<typeof TenorLimits>;

export type CPIFeature = t.TypeOf<typeof CPIFeature>;
export const CPIFeature = t.keyof(
  {
    DEATH: true,
    PERMANENT_DISABILITY: true,
    TEMPORARY_DISABILITY: true,
    LOSS_OF_INCOME: true,
    INSURANCE_OF_SERIOUS_DISEASES: true,
  },
  "CpiFeature"
);

export const CpiFeatureRecord = t.record(
  CPIFeature,
  t.union([LocalizedString, t.undefined]),
  "CpiFeaturesLabels"
);
export type CpiFeatureRecord = t.TypeOf<typeof CpiFeatureRecord>;

export const LoanParameters = t.intersection(
  [
    t.type(
      {
        loanPurpose: NonEmptyString,
        loanPurposeLabel: optionFromUndefined(t.string),
        collateralType: NonEmptyString,
        collateralTypeLabel: optionFromUndefined(t.string),
        estimatedPropertyValue: MoneyAmount,
        estimatedPropertyValueEditable: optionFromUndefined(t.boolean),
        tenor: Tenor,
        selectedInsurance: InsuranceType,
        selectedInsuranceLabel: optionFromUndefined(LocalizedString),
        insuranceFeaturesLabels: optionFromUndefined(CpiFeatureRecord),
        selectedInsuredClient: optionFromUndefined(
          NumberFromString.pipe(ApplicantIndex.props.applicantIndex)
        ),
        customFees: optionFromUndefined(Fees),
        showCustomFees: optionFromUndefined(t.boolean),
        interestRateDiscount: optionFromUndefined(PercentageLikeFromNumber),
        dateOfBirth: optionFromNullable(DateFromSQLString),
        dateOfBirthCoApplicant: optionFromNullable(DateFromSQLString),
        loanTypeLabel: optionFromUndefined(LocalizedString),
        update: optionFromUndefined(t.boolean),
        dayOfInstallment: t.number,
        nonSpecifiedProperty: optionFromUndefined(t.boolean),
        greenMortgage: optionFromUndefined(t.boolean),
        loanAmount: MoneyAmount,
      },
      "BaseParameters"
    ),
    t.union(
      [
        t.type(
          {
            loanType: LoanTypeCommon,
          },
          "CommonLoan"
        ),
        t.type(
          {
            loanType: LoanTypeCombined,
            purposeAmount: MoneyAmount,
            nonPurposeAmount: MoneyAmount,
          },
          "CombinedLoan"
        ),
      ],
      "LoanTypeParameters"
    ),
  ],
  "LoanParameters"
);

export type LoanParameters = t.TypeOf<typeof LoanParameters>;

const OfferResult = t.type(
  {
    fixationPeriod: PositiveInteger,
    insuranceFee: optionFromUndefined(MoneyAmount),
    interestRate: PercentageLikeFromNumber,
    monthlyInstallment: MoneyAmount,
  },
  "OfferResult"
);
export type OfferResult = t.TypeOf<typeof OfferResult>;

export const CalculateAmountError = t.keyof(
  {
    minPurposeLoanAmount: true,
    minNonPurposeAmount: true,
    maxNonPurposeAmount: true,
    loanAmountOutOfRange: true,
  },
  "CalculateAmountError"
);

export type CalculateAmountError = t.TypeOf<typeof CalculateAmountError>;

export const CalculateError = t.union(
  [
    t.keyof({
      genericCalculateError: true,
      minClientsAge: true,
      maxClientsAge: true,
      minCoapplicantAge: true,
      maxCoapplicantAge: true,
      minEstimatedPropertyValue: true,
      maxCustomProcessingFee: true,
      maxCustomApraisalFee: true,
      maxCustomPropertyInsuranceFee: true,
    }),
    CalculateAmountError,
  ],
  "CalculateError"
);

export type CalculateError = t.TypeOf<typeof CalculateError>;

export const CalculateErrors = optionFromArray(CalculateError);

export type CalculateErrors = t.TypeOf<typeof CalculateErrors>;

const OfferResults = t.record(t.string, OfferResult);

export const OfferPackage = t.type(
  {
    offerResults: OfferResults,
    selectedOffer: optionFromUndefined(t.string),
    offerSummary: t.type({
      duration: NonNegativeInteger,
      loanAmount: MoneyAmount,
      loanToValue: PercentageLikeFromNumber,
      ltvOverLimit: t.boolean,
      startDate: DateFromSQLString,
      expirationDate: DateFromSQLString,
      loanTypeChanged: t.boolean,
    }),
    loanAmountMax: MoneyAmount,
    loanAmountMin: MoneyAmount,
    loanAmountStepSize: Positive,
    nonPurposeAmountMax: optionFromUndefined(MoneyAmount),
    loanAmountFor80LTV: optionFromUndefined(NonNegativeInteger),
    maxClientAge: optionFromUndefined(NonNegativeInteger),
    standardFees: Fees,
    tenorLimitsYears: TenorLimits,
    offerId: optionFromUndefined(UUID),
    errors: CalculateErrors,
    showCPI: t.boolean,
    discountParametersList: t.array(DiscountParameters),
    interestRateDiscount: optionFromUndefined(PercentageLikeFromNumber),
    totalFeesDiscount: optionFromUndefined(MoneyAmount),
  },
  "OfferPackage"
);

export type OfferPackage = t.TypeOf<typeof OfferPackage>;

export type DetailRecord = t.TypeOf<typeof DetailRecord>;
export const DetailRecord = t.type({
  name: t.string,
  mktValue: t.number,
  mngValue: t.number,
  isEditable: t.boolean,
  isManagement: optionFromUndefined(t.boolean),
  isMarketing: optionFromUndefined(t.boolean),
  reasonOfDiscount: optionFromUndefined(t.string),
  mktPercent: optionFromUndefined(t.number),
  mngPercent: optionFromUndefined(t.number),
  comment: optionFromUndefined(t.string),
});

export const DiscountDetails = t.type(
  {
    details: t.array(DetailRecord),
  },
  "DiscountDetails"
);

export const Offer = t.intersection(
  [
    t.type(
      {
        greenMortgage: optionFromUndefined(t.boolean),
        nonSpecifiedProperty: optionFromUndefined(t.boolean),
        loanType: LoanType,
        loanPurposeLabel: RuntimeLocaleKey,
        fixationPeriod: PositiveInteger,
        interestRate: PercentageLikeFromNumber,
        interestRateDiscount: optionFromUndefined(PercentageLikeFromNumber),
        totalFeesDiscount: optionFromUndefined(MoneyAmount),
        loanAmount: MoneyAmount,
        monthlyInstallment: MoneyAmount,
        duration: NonNegativeInteger,
        aprPercentage: PercentageLikeFromNumber,
        totalPaidAmount: MoneyAmount,
        collateralTypeLabel: RuntimeLocaleKey,
        estimatedPropertyValue: MoneyAmount,
        loanToValue: PercentageLikeFromNumber,
        tenorValue: NonNegativeInteger,
        tenorType: TenorType,
        discountDetails: DiscountDetails,
      },
      "Offer"
    ),
    Fees,
    t.union(
      [
        t.type(
          {
            selectedPackage: CpiType,
            insuranceFee: MoneyAmount,
            insuredClientName: LocalizedString,
            discountOnIR: PercentageLikeFromNumber,
          },
          "CPI"
        ),
        t.type(
          {
            selectedPackage: CpiTypeNone,
          },
          "NoCPI"
        ),
      ],
      "OfferCPI"
    ),
  ],
  "Offer"
);

export type Offer = t.TypeOf<typeof Offer>;

const InputParameterListCode = t.keyof(
  {
    loanPurpose: true,
    collateralType: true,
    CPIPackage: true,
    dayOfInstallment: true,
    greenMortgage: true,
    reasonsOfDiscount: true,
  },
  "InputParameterListCode"
);
export type InputParameterListCode = t.TypeOf<typeof InputParameterListCode>;

const InputParameterNumberCode = t.keyof(
  {
    customAppraisalFee: true,
    estimatedPropertyValue: true,
    clientsAge: true,
    tenor: true,
    customProcessingFee: true,
    loanAmount: true,
    nonPurposeAmount: true,
    purposeLoanAmount: true,
    discountOnInterestRate: true,
  },
  "InputParameterNumberCode"
);
export type InputParameterNumberCode = t.TypeOf<
  typeof InputParameterNumberCode
>;

const InputParameterCode = t.union(
  [InputParameterListCode, InputParameterNumberCode],
  "InputParameterCode"
);
export type InputParameterCode = t.TypeOf<typeof InputParameterCode>;

const InputParameterTypeNumber = t.literal("NUMBER");
const InputParameterTypeText = t.literal("TEXT");
const InputParameterTypeList = t.literal("LIST");

export const InputParameterType = t.union(
  [InputParameterTypeNumber, InputParameterTypeText, InputParameterTypeList],
  "InputParameterType"
);
export type InputParameterType = t.TypeOf<typeof InputParameterType>;

const InputParameterOptions = nonEmptyArray(
  t.type(
    {
      code: NonEmptyString,
      label: RuntimeLocaleKey,
    },
    "Option"
  ),
  "InputParameterOptions"
);
export type InputParameterOptions = t.TypeOf<typeof InputParameterOptions>;

export const InputParameterNumber = t.type(
  {
    type: InputParameterTypeNumber,
    code: InputParameterNumberCode,
    defaultValue: NonNegative,
    numberOfDecimals: NonNegativeInteger,
  },
  "Number"
);
export type InputParameterNumber = t.TypeOf<typeof InputParameterNumber>;

const InputParameterText = t.type(
  {
    type: InputParameterTypeText,
    code: InputParameterCode,
    defaultValue: NonEmptyString,
  },
  "Text"
);
export type InputParameterText = t.TypeOf<typeof InputParameterText>;

const InputParameterList = t.type(
  {
    type: InputParameterTypeList,
    code: InputParameterListCode,
    defaultValue: NonEmptyString,
    options: InputParameterOptions,
  },
  "InputParameterList"
);
export type InputParameterList = t.TypeOf<typeof InputParameterList>;

const InputParameter = t.union(
  [InputParameterNumber, InputParameterText, InputParameterList],
  "InputParameter"
);
export type InputParameter = t.TypeOf<typeof InputParameter>;

export const InputParameters = nonEmptyArray(InputParameter, "InputParameters");
export type InputParameters = t.TypeOf<typeof InputParameters>;

export const ModifiedParameterCode = t.keyof(
  { loanAmount: true, loanPurpose: true },
  "ModifiedParameterCode"
);
export type ModifiedParameterCode = t.TypeOf<typeof ModifiedParameterCode>;

export const ModifiedParameter = t.type({
  parameter: ModifiedParameterCode,
  oldValue: t.string,
});
export type ModifiedParameter = t.TypeOf<typeof ModifiedParameter>;

export const ModifiedParameters = nonEmptyArray(
  ModifiedParameter,
  "ModifiedParameters"
);
export type ModifiedParameters = t.TypeOf<typeof ModifiedParameters>;

export type CPIPackage = t.TypeOf<typeof CPIPackage>;
export const CPIPackage = t.type({
  available: t.boolean,
  cpiFeatures: nonEmptyArray(CPIFeature),
  insuranceFee: MoneyAmount,
  type: CpiType,
});

export const CalculateOfferResponse = t.type({
  loanParameters: LoanParameters,
  offerPackage: OfferPackage,
  inputParameters: optionFromUndefined(InputParameters),
  modifiedParameters: optionFromUndefined(ModifiedParameters),
});

export type CalculateOfferResponse = t.TypeOf<typeof CalculateOfferResponse>;

export type InterestRate = t.TypeOf<typeof InterestRate>;
export const InterestRate = t.type({
  discIRManagement: t.number,
  discIRMarketing: t.number,
  editable: t.boolean,
});

export type ProcessingFee = t.TypeOf<typeof ProcessingFee>;
export const ProcessingFee = t.type({
  processingFeeMNGDiscountValue: t.number,
  processingFeeMKTDiscountValue: t.number,
  editable: t.boolean,
});

export type GreenMortgage = t.TypeOf<typeof GreenMortgage>;
export const GreenMortgage = t.type({
  greenMNGDiscount: t.number,
  greenMTGDiscount: t.number,
  editable: t.boolean,
});

export type AppraisalFee = t.TypeOf<typeof AppraisalFee>;
export const AppraisalFee = t.type({
  appraisalFeeMNGDiscountValue: t.number,
  appraisalFeeMKTDiscountValue: t.number,
  editable: t.boolean,
});

export type CPIDiscountOnProcessingFee = t.TypeOf<
  typeof CPIDiscountOnProcessingFee
>;
export const CPIDiscountOnProcessingFee = t.type({
  CPIProcessingFeeMNGDiscountValue: t.number,
  CPIProcessingFeeDiscountValue: t.number,
  editable: t.boolean,
});

export type RefinancingDiscountOnProcessingFee = t.TypeOf<
  typeof RefinancingDiscountOnProcessingFee
>;
export const RefinancingDiscountOnProcessingFee = t.type({
  refinancingProcessingFeeMNGDiscountValue: t.number,
  refinancingProcessingFeeDiscountValue: t.number,
  editable: t.boolean,
});

export type AppraisalBenefit = t.TypeOf<typeof AppraisalBenefit>;
export const AppraisalBenefit = t.type({
  appraisalBenefitMNG: t.number,
  appraisalBenefit: t.number,
  editable: t.boolean,
});

const SimpleRefinancingStatusYES = t.literal("YES");
const SimpleRefinancingStatusNO = t.literal("NO");
const SimpleRefinancingStatusNOT_KNOWN = t.literal("NOT_KNOWN");

export const SimpleRefinancingStatus = t.union(
  [
    SimpleRefinancingStatusYES,
    SimpleRefinancingStatusNO,
    SimpleRefinancingStatusNOT_KNOWN,
  ],
  "SimpleRefinancingStatus"
);
export type SimpleRefinancingStatus = t.TypeOf<typeof SimpleRefinancingStatus>;
