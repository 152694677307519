import { eq, record } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Reader } from "fp-ts/Reader";
import { Show } from "fp-ts/Show";
import * as t from "io-ts";
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { UUID } from "io-ts-types/lib/UUID";
import { MoneyAmount, optional, optionFromUndefined } from "../../globalDomain";
import { RuntimeLocaleKey } from "../../intl";

/**
 * SubPropertyType
 */
export const SubPropertyType = t.keyof(
  {
    Apartment: true,
    House: true,
    Land: true,
    ObjectOfIndividualRecreation: true,
    OtherObjects: true,
  },
  "SubPropertyType"
);

export type SubPropertyType = t.TypeOf<typeof SubPropertyType>;

export function matchSubPropertyType<A>(
  pattern: { [T in SubPropertyType]: () => A }
): Reader<SubPropertyType, A> {
  return subProperty => pattern[subProperty]();
}

export const subPropertyTypes = record.keys(SubPropertyType.keys);

/**
 * PurposeOfUse
 */
const BasePurposeOfUse = {
  OwnLivingOfCoApplicantsOrRelatives: true,
};

export const PurposeOfUseSK = t.keyof({
  ...BasePurposeOfUse,
  Others: true,
});

export const PurposeOfUseCZ = t.keyof({
  ...BasePurposeOfUse,
  Lease: true,
  Recreation: true,
  RelatedPersonOwnLivingNoPropertyShareOfCoApplicant: true,
});
export type PurposeOfUseSK = t.TypeOf<typeof PurposeOfUseSK>;
export type PurposeOfUseCZ = t.TypeOf<typeof PurposeOfUseCZ>;

export const PurposeOfUse = t.union([PurposeOfUseCZ, PurposeOfUseSK]);
export type PurposeOfUse = t.TypeOf<typeof PurposeOfUse>;

/*
 * PurposeType
 */
export const PurposeType = t.keyof(
  {
    Construction: true,
    Purchase: true,
  },
  "PurposeType"
);

export type PurposeType = t.TypeOf<typeof PurposeType>;

/**
 * PropertyInput
 */
export const PropertyInput = t.type({
  propertyId: UUID,
});

export type PropertyInput = t.TypeOf<typeof PropertyInput>;

export const eqPropertyInput = pipe(
  eq.eqString,
  eq.contramap((input: PropertyInput) => input.propertyId)
);

/**
 * ProceedPropertyAndAppraisal
 */
export const ProceedPropertyAndAppraisal = optional({
  allowMoreProperties: t.boolean,
  allowUnpledgeFirstProperty: t.boolean,
  appraisalSectionRequired: t.boolean,
  collateralType: NonEmptyString,
  nonSpecifiedProperty: t.boolean,
  pledgeSelectionError: t.boolean,
  propertyIdentificationSectionRequired: t.boolean,
  purposeType: PurposeType,
  valueOfPropertySectionRequired: t.boolean,
});

export type ProceedPropertyAndAppraisal = t.TypeOf<
  typeof ProceedPropertyAndAppraisal
>;

/**
 * CanAddSubProperty
 */
export const CanAddSubProperty = t.type({
  canAddSubProperty: t.boolean,
});

export type CanAddSubProperty = t.TypeOf<typeof CanAddSubProperty>;

/**
 * PropertyAddress
 */
const PropertyAddress = t.type(
  {
    city: t.string,
    numberOfApartment: optionFromUndefined(t.string),
    numberOfDescriptiveOrParcel: t.string,
    postCode: t.string,
    street: t.string,
  },
  "PropertyAddress"
);

export type PropertyAddress = t.TypeOf<typeof PropertyAddress>;

export const showPropertyAddress: Show<PropertyAddress> = {
  show: ({ street, city, postCode }) => `${street} - ${city} - ${postCode}`,
};

/*
 * SubProperty
 */
export const SaveSubProperty = t.type(
  {
    address: PropertyAddress,
    purpose: PurposeOfUse,
    subPropertyId: optionFromUndefined(UUID),
    type: SubPropertyType,
  },
  "SaveSubProperty"
);

export type SaveSubProperty = t.TypeOf<typeof SaveSubProperty>;

export const SubProperty = t.type(
  {
    address: PropertyAddress,
    purpose: PurposeOfUse,
    subPropertyId: UUID,
    type: SubPropertyType,
  },
  "SubProperty"
);

export type SubProperty = t.TypeOf<typeof SubProperty>;

/**
 * AppraisalType
 */
export const AppraisalType = t.keyof(
  {
    BulkOfDeveloperProjects: true,
    External: true,
    Original: true,
    PriceMap: true,
  },
  "AppraisalType"
);

export type AppraisalType = t.TypeOf<typeof AppraisalType>;

/**
 * AppraisalQuestions
 */
export const ApartmentInformation = optional(
  {
    isOutOfRange: t.boolean,
    isRecreationOrHighRisk: t.boolean,
    isTechnicalConditionUnmaintained: t.boolean,
    moreThanFiveRooms: t.boolean,
  },
  "ApartmentInformation"
);

export type ApartmentInformation = t.TypeOf<typeof ApartmentInformation>;

export const AppraisalDetails = optional(
  {
    addressOfProperty: t.string,
    dateOfEvaluation: DateFromISOString,
    firstName: t.string,
    lastName: t.string,
    valueOfProperty: MoneyAmount,
  },
  "AppraisalDetails"
);

export type AppraisalDetails = t.TypeOf<typeof AppraisalDetails>;

export const ConstructionType = t.keyof(
  {
    Brick: true,
    Panel: true,
  },
  "ConstructionType"
);

export type ConstructionType = t.TypeOf<typeof ConstructionType>;

export const constructionTypes = record.keys(ConstructionType.keys);

export const OtherInformation = optional(
  {
    commentsForAppraiser: t.string,
    districtOfProperty: t.string,
    isDifferentContact: t.boolean,
    nameOfContact: t.string,
    phoneNumber: t.string,
  },
  "OtherInformation"
);

export type OtherInformation = t.TypeOf<typeof OtherInformation>;

export const AppraisalQuestions = optional(
  {
    apartmentInformation: ApartmentInformation,
    appraisalDetails: AppraisalDetails,
    constructionType: ConstructionType,
    developerProject: t.string,
    floodZoneIV: t.boolean,
    legalConstraint: t.boolean,
    moreThan10Apartments: t.boolean,
    noLegalAccessToTheProperty: t.boolean,
    originalAppraisal: t.boolean,
    externalAppraisal: t.boolean,
    otherInformation: OtherInformation,
    otherRestrictions: t.boolean,
    pledgeForAnotherInstitution: t.boolean,
  },
  "AppraisalQuestions"
);

export type AppraisalQuestions = t.TypeOf<typeof AppraisalQuestions>;

/**
 * Appraisal
 */
export const Appraisal = t.type(
  {
    appraisalQuestions: optionFromUndefined(AppraisalQuestions),
    appraisalType: AppraisalType,
  },
  "Appraisal"
);

export type Appraisal = t.TypeOf<typeof Appraisal>;

/**
 * SaveSubProperty
 */
export const AddSubProperty = t.type({
  address: PropertyAddress,
  purpose: PurposeOfUse,
  type: SubPropertyType,
});

export type AddSubProperty = t.TypeOf<typeof AddSubProperty>;

const AddSubPropertyInput = t.type({
  propertyId: UUID,
  subProperty: AddSubProperty,
});

const AddProperty = t.type({
  subProperty: AddSubProperty,
});

const EditSubPropertyInput = t.type({
  propertyId: UUID,
  subProperty: SubProperty,
});

export const SaveSubPropertyInput = t.union(
  [AddProperty, AddSubPropertyInput, EditSubPropertyInput],
  "SaveSubPropertyInput"
);

export type SaveSubPropertyInput = t.TypeOf<typeof SaveSubPropertyInput>;

export const SaveSubPropertyOutput = optional(
  {
    canAddAnotherProperty: t.boolean,
    canAddAnotherSubProperty: t.boolean,
    propertyId: UUID,
    subPropertyId: UUID,
  },
  "SaveSubPropertyOutput"
);

export type SaveSubPropertyOutput = t.TypeOf<typeof SaveSubPropertyOutput>;

/**
 * RemoveSubProperty
 */
export const RemoveSubProperty = t.type(
  {
    propertyId: UUID,
    subPropertyId: UUID,
  },
  "RemoveSubProperty"
);

export type RemoveSubProperty = t.TypeOf<typeof RemoveSubProperty>;

/**
 * PledgeProperty
 */
export const PledgeProperty = t.type({
  pledged: optionFromUndefined(t.boolean),
  selectedPropertyId: UUID,
});

export type PledgeProperty = t.TypeOf<typeof PledgeProperty>;

export const PledgeSubPropertyOutput = t.type(
  {
    noPropertyPledgedError: optionFromUndefined(t.boolean),
    appraisalRequired: optionFromUndefined(t.boolean),
    acquiringRentalRightWarning: t.boolean,
  },
  "PledgeSubPropertyOutput"
);

export type PledgeSubPropertyOutput = t.TypeOf<typeof PledgeSubPropertyOutput>;
/*
 * ConfirmProperty
 */
export const ConfirmProperty = optional({
  acquiringRentalRightWarning: t.boolean,
  appraisalRequired: t.boolean,
  linkedToPurpose: t.boolean,
  validated: t.boolean,
});

export type ConfirmProperty = t.TypeOf<typeof ConfirmProperty>;

/**
 * ValidateProperty
 */
export const ValidateProperty = t.type({
  validated: optionFromUndefined(t.boolean),
});

export type ValidateProperty = t.TypeOf<typeof ValidateProperty>;

/** ProceedAppraisal */
export const ProceedAppraisal = optional(
  {
    acquiringRentalRightWarning: t.boolean,
    apartment: t.boolean,
    appraisalRequired: t.boolean,
    possibleAppraisals: t.array(AppraisalType),
  },
  "ProceedAppraisal"
);

export type ProceedAppraisal = t.TypeOf<typeof ProceedAppraisal>;

/** DistrictOfProperty */
export const DistrictOfProperty = t.type(
  {
    district: optionFromUndefined(t.string),
  },
  "DistrictOfProperty"
);

export type DistrictOfProperty = t.TypeOf<typeof DistrictOfProperty>;

/** DeveloperProjects */
export const DeveloperProjects = t.type(
  {
    developerProjects: t.record(t.string, t.string),
  },
  "DeveloperProjects"
);

export type DeveloperProjects = t.TypeOf<typeof DeveloperProjects>;

/** ConfirmAppraisal */
export const ConfirmAppraisalInput = t.type(
  {
    appraisal: Appraisal,
    selectedPropertyId: UUID,
  },
  "ConfirmAppraisalInput"
);

export type ConfirmAppraisalInput = t.TypeOf<typeof ConfirmAppraisalInput>;

export const ConfirmAppraisalOutput = t.type(
  {
    appraisalFee: optionFromUndefined(MoneyAmount),
  },
  "ConfirmAppraisalOutput"
);

export type ConfirmAppraisalOutput = t.TypeOf<typeof ConfirmAppraisalOutput>;

/**
 * AppraisalStatus
 */
export const AppraisalStatus = t.type(
  {
    status: t.boolean,
    lockCurrentProperty: t.boolean,
    lockAllProperties: t.boolean,
  },
  "AppraisalStatus"
);

export type AppraisalStatus = t.TypeOf<typeof AppraisalStatus>;

/**
 * PropertyValueDetails
 */
export const PropertyValueDetails = t.type({
  isVisible: t.boolean,
  isEditable: t.boolean,
  value: optionFromUndefined(MoneyAmount),
});
export type PropertyValueDetails = t.TypeOf<typeof PropertyValueDetails>;

/**
 * Risk
 */
export const Risk = t.type({
  riskId: UUID,
  riskText: RuntimeLocaleKey,
  additionalNote: t.string,
  checked: t.boolean,
});

export type Risk = t.TypeOf<typeof Risk>;

/**
 * ValueOfProperty
 */
export const PropertyValue = t.type({
  appraisalType: AppraisalType,
  actualValue: PropertyValueDetails,
  futureValue: PropertyValueDetails,
  totalActualValue: PropertyValueDetails,
  totalFutureValue: PropertyValueDetails,
  appraisalFee: PropertyValueDetails,
  propertyInsuranceFee: PropertyValueDetails,
  isDocumentMissing: t.boolean,
  isValueSufficient: t.boolean,
  isRiskSubSectionVisible: t.boolean,
  isRiskQuestionVisible: t.boolean,
  isBrokerWarning: t.boolean,
  isRisksSaved: t.boolean,
  isConfirmButtonVisible: t.boolean,
  pledgedCurrentValue: optionFromUndefined(MoneyAmount),
  isPropertyPledgedForAnotherLoan: t.boolean,
});

export type PropertyValue = t.TypeOf<typeof PropertyValue>;

/**
 * ValueOfPropertyRisk
 */
export const ValueOfPropertyRisks = t.type({
  risk: t.type({
    comment: t.string,
    hasRisks: t.boolean,
    riskList: optionFromUndefined(t.array(Risk)),
  }),
});

export const ValueOfProperty = t.type({
  isVisible: t.boolean,
  isEditable: t.boolean,
  value: MoneyAmount,
});

export type ValueOfProperty = t.TypeOf<typeof ValueOfProperty>;

export const SaveActualValueOutput = optional(
  {
    totalValueOfProperty: ValueOfProperty,
    isValueSufficient: t.boolean,
  },
  "SaveActualValueOutput"
);

export const PledgedValues = t.type({
  pledgedForAnotherLoan: t.boolean,
  pledgedCurrentValue: optionFromUndefined(t.number),
});

export type ValueOfPropertyRisks = t.TypeOf<typeof ValueOfPropertyRisks>;
