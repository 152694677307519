import { useEffect } from "react";
import { usePrevious } from "react-use";
import {
  Box,
  Button,
  DropdownOption,
  FormRow,
  Space,
  FormSection,
  Stack,
  Body,
  InlineMessage,
  unsafePositiveInteger,
  useForm,
  validators,
  LocalizedString,
  CheckboxField,
  Banner,
  ActionButtonGroup,
} from "design-system";
import { array, record, option, taskEither, boolean } from "fp-ts";
import {
  pipe,
  constant,
  constVoid,
  constNull,
  constTrue,
  constFalse,
} from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import {
  MonthYear,
  Month,
  Tenant,
  foldTenant,
  genericError,
} from "../../../globalDomain";
import { useFormatMessage } from "../../../intl";
import { useCommand } from "../../../useAPI";
import { OptionalEditYesNoField } from "../../../Common/OptionalEditFields/OptionalEditYesNoField";
import { OptionalEditMoneyField } from "../../../Common/OptionalEditFields/OptionalEditMoneyField";
import { OptionalEditDropdownField } from "../../../Common/OptionalEditFields/OptionalEditDropdownField";
import { OptionalEditMonthYearField } from "../../../Common/OptionalEditFields/OptionalEditMonthYearField";
import { OptionalEditTextField } from "../../../Common/OptionalEditFields/OptionalEditTextField";
import {
  dropdownOptionToValue,
  selectedDropdownOption,
} from "../../../Common/selectDropdownOption";
import { useTenantCurrency } from "../../../Common/useTenantCurrency";
import { useValidators } from "../../../Common/useValidators";
import { AmountLimit } from "../../api";
import {
  ConsumerLoanOrMortgage,
  CreditType,
  LoanPurpose,
  OriginalLoanPurpose,
  RefinanceLoansForLivingSelection,
  ReworkConsumerLoanOrMortgage,
} from "../../domain";
import { SelectedApplicant } from "../../mortgageDashboardUtils";
import { useFormatCreditType } from "../../useFormatCreditType";
import { useAppContext } from "../../../useAppContext";
import { toFalse, resolveAmount, checkRequiredFieldsProvided } from "./utils";
import * as api from "./api";
import * as standardLoanApi from "../../../StandardLoan/ExpensesAndAdditionalIncome/ExpensesConfirmation/CurrentLiabilitiesDialog/api";
import * as ovdApi from "../../../PreapprovedOverdraft/ExpensesPage/ExpensesConfirmation/CurrentLiabilitiesDialog/api";
import { LiabilitiesVariant } from "./Liabilities";
import { foldLiabilitiesVariant } from "../../../StandardLoan/domain";
import { useFormatReworkData } from "../../useFormatReworkData";

type Props = {
  selectedApplicant: SelectedApplicant;
  initialValues: Option<ConsumerLoanOrMortgage>;
  onSave: (loan: ConsumerLoanOrMortgage) => unknown;
  onCancel: () => unknown;
  onEdit: () => unknown;
  onRemove: () => unknown;
  isACPhase: boolean;
  isInteractingWithAnyForm: boolean;
  isViewMode: boolean;
  providersList: DropdownOption<NonEmptyString>[];
  amountLimits: {
    [key in
      | "monthlyInstallmentAmount"
      | "mtgCurrentBalance"
      | "mtgOriginalLoanAmount"]: AmountLimit;
  };
  edit: boolean;
  variant: LiabilitiesVariant;
  reworkConsumerLoan: Option<ReworkConsumerLoanOrMortgage>;
};

type FormState = {
  creditType: Option<CreditType>;
  loanPurpose: Option<LoanPurpose>;
  provider: Option<NonEmptyString>;
  originalLoanAmount: Option<number>;
  currentBalance: Option<number>;
  monthlyInstallmentAmount: Option<number>;
  endDate: Option<MonthYear>;
  usedForRefinance: Option<boolean>;
  loansForLiving: Option<boolean>;
  repaidBeforeDisbursement: Option<boolean>;
  propertyOwner: Option<boolean>;
  contractNumber: Option<string>;
  originalLoanPurpose: Option<OriginalLoanPurpose>;
};

type FormSetup = {
  tenant: Tenant;
  isACPhase: boolean;
  edit: boolean;
  manuallyAdded: boolean;
  displayLoansForLiving: boolean;
  displayOriginalLoanPurpose: boolean;
  canBeRefinanced: boolean;
  refinanceLoansForLivingSelection: RefinanceLoansForLivingSelection;
  loansForLivingDefaultValue: boolean;
  sharedCreditCoApplicantName: Option<string>;
};

function formStateFromInitialValues(
  initialValues: Option<ConsumerLoanOrMortgage>,
  variant: LiabilitiesVariant
): FormState {
  return pipe(
    initialValues,
    option.fold(
      () => ({
        creditType: option.none,
        loanPurpose: option.none,
        provider: option.none,
        originalLoanAmount: option.none,
        currentBalance: option.none,
        monthlyInstallmentAmount: option.none,
        endDate: option.none,
        usedForRefinance: option.none,
        loansForLiving: option.none,
        repaidBeforeDisbursement: option.none,
        propertyOwner: option.none,
        contractNumber: option.none,
        originalLoanPurpose: option.none,
      }),
      values => ({
        creditType: values.creditType,
        loanPurpose: values.loanPurpose,
        provider: values.provider,
        originalLoanAmount: pipe(
          foldLiabilitiesVariant(variant, {
            onMtg: () => values.approvedCreditAmount,
            onCf: () => values.originalLoanAmount,
            onOvd: () => values.originalLoanAmount,
          }),
          resolveAmount
        ),
        currentBalance: resolveAmount(values.currentBalance),
        monthlyInstallmentAmount: resolveAmount(values.monthlyInstallment),
        endDate: pipe(
          values.finalDate,
          option.map(finalDate => ({
            month: (finalDate.getMonth() + 1) as Month,
            year: unsafePositiveInteger(finalDate.getFullYear()),
          }))
        ),
        usedForRefinance: values.usedForRefinance,
        loansForLiving: values.loansForLiving,
        repaidBeforeDisbursement: values.repaidBeforeDisbursement,
        propertyOwner: values.propertyOwner,
        contractNumber: values.contractNumber,
        originalLoanPurpose: values.originalLoanPurpose,
      })
    )
  );
}

const getShowUsedForRefinance = (
  loansForLiving: Option<boolean>,
  refinanceLoansForLivingSelection: RefinanceLoansForLivingSelection
) =>
  pipe(
    loansForLiving,
    option.map(loansForLiving => {
      switch (refinanceLoansForLivingSelection) {
        case "REFINANCE_WITH_LOANS_FOR_LIVING_TRUE":
          return loansForLiving;
        case "REFINANCE_WITH_LOANS_FOR_LIVING_FALSE":
          return loansForLiving === false;
        case "REFINANCE_WITH_ANY_LOANS_FOR_LIVING":
        default:
          return true;
      }
    }),
    toFalse
  );

const getRefinanceDisbursementConflict = (values: FormState) =>
  toFalse(values.usedForRefinance) && toFalse(values.repaidBeforeDisbursement);

const getDisplayedFields = (
  values: FormState,
  formSetup: FormSetup,
  rework: Option<ReworkConsumerLoanOrMortgage>,
  r8EnableSimpleRefinancing: boolean
) => ({
  creditType: formSetup.manuallyAdded,
  loanPurpose: formSetup.manuallyAdded,
  originalLoanAmount: formSetup.manuallyAdded,
  monthlyInstallmentAmount: !formSetup.manuallyAdded,
  currentBalance: true,
  endDate: true,
  loansForLiving:
    formSetup.displayLoansForLiving && option.isSome(values.loansForLiving),
  usedForRefinance:
    (formSetup.canBeRefinanced &&
      getShowUsedForRefinance(
        option.isSome(values.loansForLiving)
          ? values.loansForLiving
          : option.of(formSetup.loansForLivingDefaultValue),
        formSetup.refinanceLoansForLivingSelection
      )) ||
    pipe(
      rework,
      option.exists(v => option.isSome(v.isUsedForRefinance))
    ),
  repaidBeforeDisbursement: pipe(
    rework,
    option.fold(constTrue, v => option.isSome(v.repaidBeforeDisbursement))
  ),
  provider:
    !getRefinanceDisbursementConflict(values) &&
    (formSetup.edit || option.isSome(values.provider)) &&
    (toFalse(values.usedForRefinance) ||
      toFalse(values.repaidBeforeDisbursement)),
  contractNumber:
    !getRefinanceDisbursementConflict(values) &&
    formSetup.tenant === "CZ" &&
    (toFalse(values.usedForRefinance) ||
      toFalse(values.repaidBeforeDisbursement)),
  propertyOwner:
    (!getRefinanceDisbursementConflict(values) &&
      toFalse(values.usedForRefinance) &&
      toFalse(values.loansForLiving)) ||
    pipe(
      rework,
      option.exists(v => option.isSome(v.propertyOwner))
    ),

  originalLoanPurpose:
    !getRefinanceDisbursementConflict(values) &&
    formSetup.displayOriginalLoanPurpose &&
    r8EnableSimpleRefinancing
      ? toFalse(values.usedForRefinance)
      : formSetup.tenant === "CZ",
});
const getRequiredFields = (
  values: FormState,
  formSetup: FormSetup,
  rework: Option<ReworkConsumerLoanOrMortgage>,
  r8EnableSimpleRefinancing: boolean
) => ({
  ...getDisplayedFields(values, formSetup, rework, r8EnableSimpleRefinancing),
  ...(!formSetup.isACPhase
    ? {
        provider: false,
        contractNumber: false,
        originalLoanPurpose: false,
      }
    : toFalse(values.usedForRefinance) ||
      toFalse(values.repaidBeforeDisbursement)
    ? {
        provider: true,
      }
    : {
        provider: false,
      }),
});

export function ConsumerLoanOrMortgageForm(props: Props) {
  const formatMessage = useFormatMessage();
  const {
    apiParameters: { tenant },
    config: { r8EnableSimpleRefinancing },
  } = useAppContext();

  const {
    defined,
    definedNoExtract,
    nonNegativeNumber,
    amountMinMax,
    maxLength,
  } = useValidators();
  const currency = useTenantCurrency();
  const formatCreditType = useFormatCreditType();

  const { formatIssues, formatFeedback } = useFormatReworkData(
    props.reworkConsumerLoan
  );

  function formatLoanPurpose(loanPurpose: LoanPurpose): LocalizedString {
    switch (loanPurpose) {
      case "PURPOSE":
        return formatMessage(
          "Liabilities.AddConsumerLoanOrMortgage.loanPurpose.purpose"
        );
      case "NOT_PURPOSE":
        return formatMessage(
          "Liabilities.AddConsumerLoanOrMortgage.loanPurpose.notPurpose"
        );
    }
  }

  function formatOriginalLoanPurpose(
    originalLoanPurpose: OriginalLoanPurpose
  ): LocalizedString {
    switch (originalLoanPurpose) {
      case "PURCHASE":
        return formatMessage(
          "Liabilities.AddConsumerLoanOrMortgage.originalLoanPurpose.purchase"
        );
      case "CONSTRUCTION":
        return formatMessage(
          "Liabilities.AddConsumerLoanOrMortgage.originalLoanPurpose.construction"
        );
      case "RECONSTRUCTION":
        return formatMessage(
          "Liabilities.AddConsumerLoanOrMortgage.originalLoanPurpose.reconstruction"
        );
      case "REVERSE_REPAYMENT":
        return formatMessage(
          "Liabilities.AddConsumerLoanOrMortgage.originalLoanPurpose.reverseRepayment"
        );
      case "REPAYMENT_OF_PREVIOUSLY_GRANTED_LOAN":
        return formatMessage(
          "Liabilities.AddConsumerLoanOrMortgage.originalLoanPurpose.repaymentOfPreviouslyGrantedLoan"
        );
      case "REPAYMENT_OF_PREVIOUSLY_GRANTED_NON_PURPOSE_LOAN":
        return formatMessage(
          "Liabilities.AddConsumerLoanOrMortgage.originalLoanPurpose.repaymentOfPreviouslyGrantedNonPurposeLoan"
        );
      case "NOT_PURPOSE":
      default:
        return formatMessage(
          "Liabilities.AddConsumerLoanOrMortgage.originalLoanPurpose.notPurpose"
        );
    }
  }

  const addLoan = useCommand(api.addConsumerLoanOrMortgage);
  const editLoan = useCommand(api.editConsumerLoanOrMortgage);
  const addLoanStandardLoan = useCommand(
    standardLoanApi.addConsumerLoanOrMortgage
  );

  const addLoanOverdraft = useCommand(ovdApi.addConsumerLoanOrMortgage);

  const editLoanStandardLoan = useCommand(
    standardLoanApi.editConsumerLoanOrMortgage
  );

  const editLoanOverdraft = useCommand(ovdApi.editConsumerLoanOrMortgage);

  const monthlyInstallment = pipe(
    props.initialValues,
    option.fold(
      () => option.none,
      a => a.monthlyInstallment
    )
  );
  const currentBalance = pipe(
    props.initialValues,
    option.fold(
      () => option.none,
      a => a.currentBalance
    )
  );
  const finalDate = pipe(
    props.initialValues,
    option.fold(
      () => option.none,
      a => a.finalDate
    )
  );

  useEffect(() => {
    if (
      (r8EnableSimpleRefinancing && !option.isSome(monthlyInstallment)) ||
      !option.isSome(currentBalance) ||
      !option.isSome(finalDate)
    ) {
      props.onEdit();
    }
  }, [monthlyInstallment, currentBalance, finalDate]);

  const formSetup = pipe(
    props.initialValues,
    option.fold(
      (): FormSetup => ({
        tenant,
        edit: props.edit,
        isACPhase: props.isACPhase,
        manuallyAdded: true,
        displayLoansForLiving: foldLiabilitiesVariant(props.variant, {
          onMtg: constTrue,
          onCf: constFalse,
          onOvd: constFalse,
        }),
        displayOriginalLoanPurpose: true,
        canBeRefinanced: foldLiabilitiesVariant(props.variant, {
          onMtg: constTrue,
          onCf: constFalse,
          onOvd: constFalse,
        }),
        refinanceLoansForLivingSelection: "REFINANCE_WITH_ANY_LOANS_FOR_LIVING",
        loansForLivingDefaultValue: false,
        sharedCreditCoApplicantName: option.none,
      }),
      values => ({
        tenant,
        edit:
          props.edit ||
          !option.isSome(values.monthlyInstallment) ||
          !option.isSome(values.currentBalance) ||
          !option.isSome(values.finalDate),
        isACPhase: props.isACPhase,
        manuallyAdded: values.manuallyAdded,
        canBeRefinanced: toFalse(values.canBeRefinanced),
        displayLoansForLiving: toFalse(values.displayLoansForLiving),
        displayOriginalLoanPurpose: toFalse(values.displayOriginalLoanPurpose),
        refinanceLoansForLivingSelection: pipe(
          values.refinanceLoansForLivingSelection,
          option.getOrElse(
            constant(
              "REFINANCE_WITH_ANY_LOANS_FOR_LIVING" as RefinanceLoansForLivingSelection
            )
          )
        ),
        loansForLivingDefaultValue: toFalse(values.loansForLivingDefaultValue),
        sharedCreditCoApplicantName: values.sharedCreditCoApplicantName,
      })
    )
  );

  const { fieldProps, handleSubmit, setValues, values } = useForm(
    {
      initialValues: formStateFromInitialValues(
        props.initialValues,
        props.variant
      ),
      fieldValidators: function (values) {
        const requiredFields = getRequiredFields(
          values,
          formSetup,
          props.reworkConsumerLoan,
          r8EnableSimpleRefinancing
        );
        console.log(requiredFields, values);
        return {
          creditType: formSetup.manuallyAdded ? definedNoExtract() : undefined,
          loanPurpose:
            formSetup.manuallyAdded && props.variant !== "OVD"
              ? definedNoExtract()
              : undefined,
          provider: requiredFields.provider ? definedNoExtract() : undefined,
          originalLoanAmount: validators.inSequence(
            defined(),
            nonNegativeNumber,
            amountMinMax(
              props.amountLimits.mtgOriginalLoanAmount.min,
              props.amountLimits.mtgOriginalLoanAmount.max
            )
          ),
          currentBalance: validators.inSequence(
            defined(),
            nonNegativeNumber,
            amountMinMax(
              props.amountLimits.mtgCurrentBalance.min,
              props.amountLimits.mtgCurrentBalance.max
            )
          ),
          monthlyInstallmentAmount: validators.inSequence(
            defined(),
            nonNegativeNumber,
            amountMinMax(
              props.amountLimits.monthlyInstallmentAmount.min,
              props.amountLimits.monthlyInstallmentAmount.max
            )
          ),
          endDate: defined(),
          loansForLiving: defined(),
          usedForRefinance: requiredFields.usedForRefinance
            ? validators.inSequence(
                definedNoExtract(),
                validators.fromPredicate(
                  () => !getRefinanceDisbursementConflict(values),
                  formatMessage(
                    "Mortgage.CBResults.Liabilities.selectForRefinancingError"
                  )
                )
              )
            : undefined,
          repaidBeforeDisbursement:
            requiredFields.repaidBeforeDisbursement && props.variant !== "CF"
              ? definedNoExtract()
              : undefined,
          propertyOwner:
            requiredFields.propertyOwner && props.variant !== "CF"
              ? definedNoExtract()
              : undefined,
          contractNumber:
            requiredFields.contractNumber && props.variant !== "CF"
              ? validators.inSequence(
                  definedNoExtract(),
                  validators.validateIfDefined(maxLength(200))
                )
              : validators.validateIfDefined(maxLength(200)),
          originalLoanPurpose: requiredFields.originalLoanPurpose
            ? definedNoExtract()
            : undefined,
        };
      },
    },
    {
      onSubmit: values =>
        pipe(
          {
            applicantIndex: props.selectedApplicant.index,
            creditType: values.creditType,
            loanPurpose: values.loanPurpose,
            provider: values.provider,
            approvedCreditAmount: {
              currency,
              amount: values.originalLoanAmount,
            },
            currentBalance: { currency, amount: values.currentBalance },
            monthlyInstallment: {
              currency,
              amount: values.monthlyInstallmentAmount,
            },
            endDate: values.endDate,
            usedForRefinance: values.usedForRefinance,
            loansForLiving: values.loansForLiving,
            repaidBeforeDisbursement: values.repaidBeforeDisbursement,
            propertyOwner: values.propertyOwner,
            contractNumber: values.contractNumber,
            originalLoanPurpose: values.originalLoanPurpose,
          },
          taskEither.of,
          taskEither.chain(newValues =>
            pipe(
              props.initialValues,
              option.fold(
                () =>
                  pipe(
                    foldLiabilitiesVariant(props.variant, {
                      onMtg: () =>
                        addLoan({ ...newValues, manuallyAdded: true }),
                      onCf: () => {
                        return option.isSome(newValues.creditType) &&
                          option.isSome(newValues.loanPurpose)
                          ? addLoanStandardLoan({
                              creditType: newValues.creditType.value,
                              currentBalance: newValues.currentBalance,
                              finalDateOfTheLoan: newValues.endDate,
                              loanPurpose: newValues.loanPurpose.value,
                              monthlyInstallment: newValues.monthlyInstallment,
                              originalLoanAmount:
                                newValues.approvedCreditAmount,
                              usedForRefinance: newValues.usedForRefinance,
                            })
                          : taskEither.left(() => genericError);
                      },
                      onOvd: () => {
                        return option.isSome(newValues.creditType)
                          ? addLoanOverdraft({
                              creditType: newValues.creditType.value,
                              currentBalance: newValues.currentBalance,
                              finalDateOfTheLoan: newValues.endDate,
                              monthlyInstallment: newValues.monthlyInstallment,
                              originalLoanAmount:
                                newValues.approvedCreditAmount,
                              usedForRefinance: newValues.usedForRefinance,
                            })
                          : taskEither.left(() => genericError);
                      },
                    }),
                    taskEither.chain(loan =>
                      taskEither.fromIO(() => props.onSave(loan))
                    )
                  ),
                initialValues =>
                  pipe(
                    foldLiabilitiesVariant(props.variant, {
                      onMtg: () =>
                        editLoan({
                          ...newValues,
                          manuallyAdded: formSetup.manuallyAdded,
                          usedForRefinance: toFalse(
                            initialValues.canBeRefinanced
                          )
                            ? newValues.usedForRefinance
                            : option.some(false),
                          recordId: initialValues.recordId,
                        }),
                      onCf: () => {
                        return option.isSome(newValues.creditType) &&
                          option.isSome(newValues.loanPurpose)
                          ? editLoanStandardLoan({
                              creditType: newValues.creditType.value,
                              currentBalance: newValues.currentBalance,
                              finalDate: newValues.endDate,
                              loanPurpose: newValues.loanPurpose.value,
                              monthlyInstallmentAmount:
                                newValues.monthlyInstallment,
                              approvedCreditAmount:
                                newValues.approvedCreditAmount,
                              recordId: initialValues.recordId,
                            })
                          : taskEither.left(() => genericError);
                      },
                      onOvd: () => {
                        return option.isSome(newValues.creditType)
                          ? editLoanOverdraft({
                              creditType: newValues.creditType.value,
                              currentBalance: newValues.currentBalance,
                              finalDate: newValues.endDate,
                              monthlyInstallmentAmount:
                                newValues.monthlyInstallment,
                              approvedCreditAmount:
                                newValues.approvedCreditAmount,
                              recordId: initialValues.recordId,
                            })
                          : taskEither.left(() => genericError);
                      },
                    }),
                    taskEither.chain(loan =>
                      taskEither.fromIO(() => props.onSave(loan))
                    )
                  )
              )
            )
          )
        ),
    }
  );

  const previousEdit = usePrevious(formSetup.edit);
  useEffect(() => {
    if (!formSetup.edit || formSetup.edit === previousEdit) return;

    setValues({
      originalLoanAmount: values.originalLoanAmount,
      loansForLiving: pipe(
        values.loansForLiving,
        option.getOrElse(constant(formSetup.loansForLivingDefaultValue)),
        option.some
      ),
      usedForRefinance: pipe(values.usedForRefinance, toFalse, option.some),
      repaidBeforeDisbursement: pipe(
        values.repaidBeforeDisbursement,
        toFalse,
        option.some
      ),
    });
  }, [formSetup, previousEdit, values, setValues]);

  const handleCancel = () => {
    pipe(
      formStateFromInitialValues(props.initialValues, props.variant),
      setValues
    );
    props.onCancel();
  };

  const creditTypeFieldProps = fieldProps("creditType");
  const creditTypeOptions = pipe(
    CreditType.keys,
    record.keys,
    array.map(value => ({ value, label: formatCreditType(value) }))
  );
  const creditTypeValue = selectedDropdownOption(
    creditTypeFieldProps.value,
    creditTypeOptions
  );
  const creditTypeOnChange = (value: Option<DropdownOption<CreditType>>) => {
    creditTypeFieldProps.onChange(dropdownOptionToValue(value));
  };

  const loanPurposeFieldProps = fieldProps("loanPurpose");
  const loanPurposeOptions = pipe(
    LoanPurpose.keys,
    record.keys,
    array.map(value => ({ value, label: formatLoanPurpose(value) }))
  );
  const loanPurposeValue = selectedDropdownOption(
    loanPurposeFieldProps.value,
    loanPurposeOptions
  );
  const loanPurposeOnChange = (value: Option<DropdownOption<LoanPurpose>>) => {
    loanPurposeFieldProps.onChange(dropdownOptionToValue(value));
    if (
      formSetup.manuallyAdded &&
      option.isSome(value) &&
      value.value.value === "PURPOSE" &&
      option.isSome(values.loansForLiving) &&
      !values.loansForLiving.value
    ) {
      fieldProps("usedForRefinance").onChange(option.some(false));
    }
  };
  const originalLoanPurposeFieldProps = fieldProps("originalLoanPurpose");
  const originalLoanPurposeOptions = pipe(
    OriginalLoanPurpose.keys,
    record.keys,
    array.filter(key =>
      foldTenant(
        tenant,
        () =>
          r8EnableSimpleRefinancing
            ? true
            : key !== "REPAYMENT_OF_PREVIOUSLY_GRANTED_LOAN" &&
              key !== "REPAYMENT_OF_PREVIOUSLY_GRANTED_NON_PURPOSE_LOAN",
        () =>
          key !== "REPAYMENT_OF_PREVIOUSLY_GRANTED_LOAN" &&
          key !== "REPAYMENT_OF_PREVIOUSLY_GRANTED_NON_PURPOSE_LOAN"
      )
    ),
    array.map(value => ({ value, label: formatOriginalLoanPurpose(value) }))
  );
  const originalLoanPurposeValue = selectedDropdownOption(
    originalLoanPurposeFieldProps.value,
    originalLoanPurposeOptions
  );
  const originalLoanPurposeOnChange = (
    value: Option<DropdownOption<OriginalLoanPurpose>>
  ) => {
    originalLoanPurposeFieldProps.onChange(dropdownOptionToValue(value));
  };

  const providerFieldProps = fieldProps("provider");
  const providerOnChange = (value: Option<DropdownOption<NonEmptyString>>) => {
    providerFieldProps.onChange(dropdownOptionToValue(value));
  };

  const currentYear = new Date().getFullYear();

  const displayedFields = getDisplayedFields(
    values,
    formSetup,
    props.reworkConsumerLoan,
    r8EnableSimpleRefinancing
  );

  return (
    <FormSection>
      {(displayedFields.creditType || displayedFields.loanPurpose) && (
        <FormRow type="1-1">
          {displayedFields.creditType ? (
            <OptionalEditDropdownField
              {...creditTypeFieldProps}
              clearable={false}
              searchable={false}
              label={formatMessage(
                "Liabilities.AddConsumerLoanOrMortgage.creditTypeLabel"
              )}
              placeholder={formatMessage(
                "Liabilities.AddConsumerLoanOrMortgage.creditTypeLabel"
              )}
              options={creditTypeOptions}
              value={creditTypeValue}
              onChange={creditTypeOnChange}
              edit={formSetup.edit}
              issues={formatIssues("creditType", creditTypeFieldProps.issues)}
              feedback={formatFeedback("creditType")}
            />
          ) : null}
          {displayedFields.loanPurpose && props.variant !== "OVD" ? (
            <OptionalEditDropdownField
              {...loanPurposeFieldProps}
              clearable={false}
              searchable={false}
              label={formatMessage(
                "Liabilities.AddConsumerLoanOrMortgage.loanPurposeLabel"
              )}
              placeholder={formatMessage(
                "Liabilities.AddConsumerLoanOrMortgage.loanPurposeLabel"
              )}
              options={loanPurposeOptions}
              value={loanPurposeValue}
              onChange={loanPurposeOnChange}
              edit={formSetup.edit}
            />
          ) : null}
        </FormRow>
      )}
      <FormRow type="1-1">
        {formSetup.manuallyAdded ? (
          <OptionalEditMoneyField
            {...fieldProps("originalLoanAmount")}
            min={props.amountLimits.mtgOriginalLoanAmount.min}
            max={props.amountLimits.mtgOriginalLoanAmount.max}
            currency={currency}
            label={formatMessage(
              "Liabilities.AddConsumerLoanOrMortgage.originalLoanAmountLabel"
            )}
            placeholder={formatMessage(
              "Liabilities.AddConsumerLoanOrMortgage.originalLoanAmountLabel"
            )}
            edit={formSetup.edit}
            issues={formatIssues(
              "approvedCreditAmount",
              fieldProps("originalLoanAmount").issues
            )}
            feedback={formatFeedback("approvedCreditAmount")}
          />
        ) : (
          <OptionalEditMoneyField
            {...fieldProps("monthlyInstallmentAmount")}
            currency={currency}
            label={formatMessage(
              "Liabilities.AddConsumerLoanOrMortgage.monthlyInstallmentAmountLabel"
            )}
            placeholder={formatMessage(
              "Liabilities.AddConsumerLoanOrMortgage.monthlyInstallmentAmountLabel"
            )}
            edit={
              r8EnableSimpleRefinancing &&
              !option.isSome(fieldProps("monthlyInstallmentAmount").value)
            }
            issues={formatIssues(
              "monthlyInstallment",
              fieldProps("monthlyInstallmentAmount").issues
            )}
            feedback={formatFeedback("monthlyInstallment")}
          />
        )}
        <OptionalEditMoneyField
          {...fieldProps("currentBalance")}
          min={props.amountLimits.mtgCurrentBalance.min}
          max={props.amountLimits.mtgCurrentBalance.max}
          currency={currency}
          label={formatMessage(
            "Liabilities.AddConsumerLoanOrMortgage.currentBalanceLabel"
          )}
          placeholder={formatMessage(
            "Liabilities.AddConsumerLoanOrMortgage.currentBalanceLabel"
          )}
          edit={
            (props.edit && formSetup.manuallyAdded) ||
            (r8EnableSimpleRefinancing &&
              !option.isSome(fieldProps("currentBalance").value))
          }
          issues={formatIssues(
            "currentBalance",
            fieldProps("currentBalance").issues
          )}
          feedback={formatFeedback("currentBalance")}
        />
      </FormRow>
      {formSetup.manuallyAdded && (
        <FormRow type="1-1">
          <OptionalEditMoneyField
            {...fieldProps("monthlyInstallmentAmount")}
            min={props.amountLimits.monthlyInstallmentAmount.min}
            max={props.amountLimits.monthlyInstallmentAmount.max}
            currency={currency}
            label={formatMessage(
              "Liabilities.AddConsumerLoanOrMortgage.monthlyInstallmentAmountLabel"
            )}
            placeholder={formatMessage(
              "Liabilities.AddConsumerLoanOrMortgage.monthlyInstallmentAmountLabel"
            )}
            edit={formSetup.edit}
            issues={formatIssues(
              "monthlyInstallment",
              fieldProps("monthlyInstallmentAmount").issues
            )}
            feedback={formatFeedback("monthlyInstallment")}
          />
          {null}
        </FormRow>
      )}
      <FormRow type="full">
        <OptionalEditMonthYearField
          {...fieldProps("endDate")}
          label={formatMessage(
            "Liabilities.AddConsumerLoanOrMortgage.endDateLabel"
          )}
          searchable={false}
          clearable={false}
          minYear={currentYear}
          maxYear={currentYear + 50}
          ascending
          edit={
            (formSetup.edit && formSetup.manuallyAdded) ||
            (r8EnableSimpleRefinancing &&
              !option.isSome(fieldProps("endDate").value))
          }
          issues={formatIssues("finalDate", fieldProps("endDate").issues)}
          feedback={formatFeedback("finalDate")}
        />
      </FormRow>
      {displayedFields.loansForLiving && (
        <FormRow type="full">
          <OptionalEditYesNoField
            {...fieldProps("loansForLiving")}
            label={formatMessage(
              "Mortgage.CBResults.Liabilities.loansForLiving"
            )}
            onChange={newValue => {
              fieldProps("loansForLiving").onChange(newValue);
              pipe(
                newValue,
                option.filter(value => value === false),
                option.fold(constVoid, () => {
                  fieldProps("usedForRefinance").onChange(option.some(false));
                })
              );
            }}
            edit={formSetup.edit}
            issues={formatIssues(
              "loansForLiving",
              fieldProps("loansForLiving").issues
            )}
            feedback={formatFeedback("loansForLiving")}
          />
        </FormRow>
      )}
      {displayedFields.usedForRefinance &&
        formSetup.edit &&
        props.variant !== "OVD" &&
        !(
          option.isSome(values.loanPurpose) &&
          values.loanPurpose.value === "PURPOSE" &&
          option.isSome(values.loansForLiving) &&
          !values.loansForLiving.value &&
          option.isSome(values.creditType) &&
          values.creditType.value === "MORTGAGE_LOAN" &&
          formSetup.manuallyAdded
        ) && (
          <FormRow type="full">
            <CheckboxField
              {...fieldProps("usedForRefinance")}
              label={formatMessage(
                "Mortgage.CBResults.Liabilities.selectForRefinancing"
              )}
              value={toFalse(fieldProps("usedForRefinance").value)}
              onChange={(newValue: boolean) =>
                pipe(
                  newValue,
                  option.some,
                  fieldProps("usedForRefinance").onChange
                )
              }
              issues={formatIssues("isUsedForRefinance", option.none)}
            />
          </FormRow>
        )}
      {displayedFields.repaidBeforeDisbursement &&
        props.variant !== "CF" &&
        props.variant !== "OVD" &&
        formSetup.edit && (
          <FormRow type="full">
            <CheckboxField
              {...fieldProps("repaidBeforeDisbursement")}
              label={formatMessage(
                "Mortgage.CBResults.Liabilities.repaidBeforeDisbursement"
              )}
              value={toFalse(fieldProps("repaidBeforeDisbursement").value)}
              onChange={(newValue: boolean) =>
                pipe(
                  newValue,
                  option.some,
                  fieldProps("repaidBeforeDisbursement").onChange
                )
              }
              issues={formatIssues(
                "repaidBeforeDisbursement",
                fieldProps("repaidBeforeDisbursement").issues
              )}
            />
          </FormRow>
        )}
      {getRefinanceDisbursementConflict(values) && props.variant !== "CF" && (
        <Banner
          type="error"
          content={formatMessage(
            "Mortgage.CBResults.Liabilities.selectForRefinancingError"
          )}
          actions={option.none}
          title={option.none}
        />
      )}

      {(displayedFields.provider || displayedFields.contractNumber) &&
        props.variant !== "CF" && (
          <FormRow type="1-1">
            {displayedFields.provider ? (
              <OptionalEditDropdownField
                {...providerFieldProps}
                clearable={false}
                searchable
                label={formatMessage(
                  !formSetup.isACPhase && formSetup.edit
                    ? "Liabilities.AddConsumerLoanOrMortgage.providerLabelOptional"
                    : "Liabilities.AddConsumerLoanOrMortgage.providerLabel"
                )}
                placeholder={formatMessage(
                  "Liabilities.AddConsumerLoanOrMortgage.providerLabel"
                )}
                options={props.providersList}
                value={selectedDropdownOption(
                  providerFieldProps.value,
                  props.providersList
                )}
                onChange={providerOnChange}
                edit={formSetup.edit}
                issues={formatIssues("provider", providerFieldProps.issues)}
                feedback={formatFeedback("provider")}
              />
            ) : null}
            {displayedFields.contractNumber ? (
              <OptionalEditTextField
                {...fieldProps("contractNumber")}
                value={pipe(
                  fieldProps("contractNumber").value,
                  option.getOrElse(constant(""))
                )}
                onChange={value =>
                  pipe(
                    value,
                    option.fromPredicate(value => value.length > 0),
                    fieldProps("contractNumber").onChange
                  )
                }
                label={formatMessage(
                  !formSetup.isACPhase && formSetup.edit
                    ? "Mortgage.CBResults.Liabilities.contractNumberOptional"
                    : "Mortgage.CBResults.Liabilities.contractNumber"
                )}
                placeholder={formatMessage(
                  "Mortgage.CBResults.Liabilities.contractNumber"
                )}
                edit={formSetup.edit}
                issues={formatIssues(
                  "contractNumber",
                  fieldProps("contractNumber").issues
                )}
                feedback={formatFeedback("contractNumber")}
              />
            ) : null}
          </FormRow>
        )}

      {displayedFields.propertyOwner && (
        <FormRow type="full">
          <OptionalEditYesNoField
            {...fieldProps("propertyOwner")}
            label={formatMessage(
              "Mortgage.CBResults.Liabilities.propertyOwner"
            )}
            description={formatMessage(
              "Mortgage.CBResults.Liabilities.toBeProvidedNote.consumerLoanOrMortgage"
            )}
            edit={formSetup.edit}
            issues={formatIssues(
              "propertyOwner",
              fieldProps("propertyOwner").issues
            )}
            feedback={formatFeedback("propertyOwner")}
          />
        </FormRow>
      )}
      {displayedFields.originalLoanPurpose && (
        <FormRow type="full">
          <OptionalEditDropdownField
            {...originalLoanPurposeFieldProps}
            clearable={false}
            searchable={false}
            label={formatMessage(
              !formSetup.isACPhase && formSetup.edit
                ? "Mortgage.CBResults.Liabilities.originalLoanPurposeOptional"
                : "Mortgage.CBResults.Liabilities.originalLoanPurpose"
            )}
            placeholder={formatMessage(
              "Mortgage.CBResults.Liabilities.originalLoanPurpose"
            )}
            options={originalLoanPurposeOptions}
            value={originalLoanPurposeValue}
            onChange={originalLoanPurposeOnChange}
            edit={formSetup.edit}
            issues={formatIssues(
              "originalLoanPurpose",
              originalLoanPurposeFieldProps.issues
            )}
            feedback={formatFeedback("originalLoanPurpose")}
          />
        </FormRow>
      )}

      {!formSetup.edit && toFalse(values.repaidBeforeDisbursement) && (
        <InlineMessage
          type="informative"
          size="medium"
          message={formatMessage(
            "Mortgage.CBResults.Liabilities.repaidBeforeDisbursement"
          )}
        />
      )}

      {!formSetup.edit &&
        props.variant !== "OVD" &&
        pipe(
          formSetup.sharedCreditCoApplicantName,
          option.fold(
            constant(
              toFalse(values.usedForRefinance) ? (
                <InlineMessage
                  type="informative"
                  size="medium"
                  message={formatMessage(
                    "Mortgage.CBResults.Liabilities.loan.selectedForRefinancing"
                  )}
                />
              ) : null
            ),
            sharedCreditCoApplicantName => (
              <Stack units={4} column>
                <Body size="medium" weight="regular">
                  {formatMessage(
                    "Mortgage.CBResults.Liabilities.coApplicantOfBorrower",
                    {
                      borrower: sharedCreditCoApplicantName,
                    }
                  )}
                </Body>
                {toFalse(values.usedForRefinance) && props.variant !== "OVD" ? (
                  <InlineMessage
                    type="informative"
                    size="medium"
                    message={formatMessage(
                      "Mortgage.CBResults.Liabilities.loan.selectedForRefinancing"
                    )}
                  />
                ) : null}
              </Stack>
            )
          )
        )}

      {formSetup.isACPhase &&
        !formSetup.edit &&
        !checkRequiredFieldsProvided(
          values,
          getRequiredFields(
            values,
            formSetup,
            props.reworkConsumerLoan,
            r8EnableSimpleRefinancing
          )
        ) && (
          <Banner
            type="warning"
            content={formatMessage(
              "Mortgage.CBResults.Liabilities.provideMandatoryDetailsWarning"
            )}
            onDismiss={option.none}
            actions={option.none}
            title={option.none}
          />
        )}

      {formSetup.edit ? (
        <ActionButtonGroup
          hAlignContent="right"
          actions={[
            {
              action: handleCancel,
              label: formatMessage("Cancel"),
              disabled: false,
              variant: "text",
            }, // as Action,
            {
              action: handleSubmit,
              labels: pipe(
                props.initialValues,
                option.fold(
                  () => ({
                    normal: formatMessage("Liabilities.Add"),
                    loading: formatMessage("Liabilities.Add"),
                    error: formatMessage("Liabilities.Add"),
                    success: formatMessage("Liabilities.Add"),
                  }),
                  () => ({
                    normal: formatMessage("Save"),
                    loading: formatMessage("Save"),
                    error: formatMessage("Save"),
                    success: formatMessage("Save"),
                  })
                )
              ),
              disabled: false,
              variant: "loading",
              type: "submit",
            }, // as Action
          ]}
        />
      ) : (
        <Box vAlignContent="center">
          <Space fluid />

          {formSetup.manuallyAdded && !props.isViewMode && (
            <Button
              disabled={props.isInteractingWithAnyForm}
              variant="text"
              size="default"
              label={formatMessage(
                "Mortgage.CBResults.Liabilities.removeLabel"
              )}
              action={props.onRemove}
            />
          )}
          <Space units={8} />
          {foldLiabilitiesVariant(props.variant, {
            onCf: () =>
              formSetup.manuallyAdded ? (
                <Button
                  disabled={props.isInteractingWithAnyForm}
                  variant="text"
                  size="default"
                  label={formatMessage(
                    "Mortgage.CBResults.Liabilities.editLabel"
                  )}
                  action={props.onEdit}
                />
              ) : null,
            onOvd: () =>
              formSetup.manuallyAdded ? (
                <Button
                  disabled={props.isInteractingWithAnyForm}
                  variant="text"
                  size="default"
                  label={formatMessage(
                    "Mortgage.CBResults.Liabilities.editLabel"
                  )}
                  action={props.onEdit}
                />
              ) : null,
            onMtg: () =>
              pipe(
                props.isViewMode,
                boolean.fold(
                  () => (
                    <Button
                      disabled={props.isInteractingWithAnyForm}
                      variant="text"
                      size="default"
                      label={formatMessage(
                        "Mortgage.CBResults.Liabilities.editLabel"
                      )}
                      action={props.onEdit}
                    />
                  ),
                  constNull
                )
              ),
          })}
        </Box>
      )}
    </FormSection>
  );
}
