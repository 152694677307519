import { Stack, Body, Dialog } from "design-system";
import { option } from "fp-ts";
import { useFormatMessage } from "../intl";

type Props = {
  onDismiss: () => unknown;
};

export function RiskyActivitiesDialog(props: Props) {
  const formatMessage = useFormatMessage();
  return (
    <Dialog
      variant="left"
      size="small"
      title={formatMessage("KYC.RiskyActivitiesModal.title")}
      onDismiss={option.some(props.onDismiss)}
      actions={[]}
    >
      <Stack column units={3}>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity0`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity1`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity2`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity3`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity4`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity5`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity6`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity7`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity8`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity9`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity10`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity11`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity12`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity13`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity14`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity15`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity16`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity17`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity18`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity19`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity20`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity21`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity22`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity23`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity24`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity25`)}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage(`KYC.RiskyActivitiesModal.activity26`)}
        </Body>
      </Stack>
    </Dialog>
  );
}
