import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { either, option, taskEither } from "fp-ts";
import { Option } from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import * as api from "./api";
import { FileContent, PositiveInteger } from "design-system";
import { DocumentUploadError } from "../../Common/Dialogs/DocumentUploadDialog/domain";
import { foldTenant, Tenant } from "../../globalDomain";
import { DocumentMime } from "../../Common/documentAPI";

export type StepStatus = "complete" | "active";

export type DocumentFlow =
  | {
      variant: "MainPage";
    }
  | {
      variant: "ExceptionProcess";
    }
  | {
      variant: "UploadRequiredDocument";
      document: api.Document;
      sectionElementId: Option<string>;
    }
  | {
      variant: "UploadOptionalDocument";
      dialogDocId: NonEmptyString;
      documentTab: api.DocumentTab;
    }
  | {
      variant: "UploadProofOfIncomeDocument";
      document: api.Document;
    }
  | {
      variant: "RemoveDocument";
      document: api.UploadedDocument;
    }
  | {
      variant: "ViewDocument";
      document: api.UploadedDocument;
    }
  | {
      variant: "ExceptionProcessWithDocument";
      document: api.Document;
    }
  | {
      variant: "DeleteRequiredDocument";
      document: api.Document;
      sectionElementId: Option<string>;
    }
  | {
      variant: "RestoreDeletedDocument";
      document: api.Document;
      sectionElementId: Option<string>;
    }
  | {
      variant: "DeleteRequiredDocumentAlreadyUploaded";
      uploadedDocument: api.UploadedDocument;
    };

export type UploadResultTaskEither = taskEither.TaskEither<
  DocumentUploadError,
  {
    status: api.DocumentUploadStatus;
    maxLengthNote: PositiveInteger;
    fileType: DocumentMime;
    fileBase64: FileContent;
  }
>;

export type OtherDocumentsDropdownKey =
  | "OtherDocuments"
  | "RealEstateDocuments"
  | "LoanDocuments"
  | "ReceiptDocuments";

export const optionalDocumentToIdMapCZ: Record<
  OtherDocumentsDropdownKey,
  NonEmptyString
> = {
  OtherDocuments: "37E51279-028C-487C-8B72-669949883EF2" as NonEmptyString,
  RealEstateDocuments: "3B566DD0-2C8A-4F05-86E4-C1C0362E9B4E" as NonEmptyString,
  LoanDocuments: "968C02C8-6957-4898-8FDA-EFCA9B117B8B" as NonEmptyString,
  ReceiptDocuments: "D6330AE7-CE1C-4780-84F6-F71EF7935C8A" as NonEmptyString,
};

export const optionalDocumentToIdMapSK: Record<
  OtherDocumentsDropdownKey,
  NonEmptyString
> = {
  OtherDocuments: "11046937-948E-44B7-8609-FD6723E19102" as NonEmptyString,
  RealEstateDocuments: "B1F406EF-FE1F-4A88-8200-6F7B94FC1931" as NonEmptyString,
  LoanDocuments: "F4CB6B87-3E72-4281-884B-E6D7B645E871" as NonEmptyString,
  ReceiptDocuments: "6AEE7CEC-B766-4E98-9BE2-FC08FF44F289" as NonEmptyString,
};

export const optionalDocumentToIdMap = (tenant: Tenant) =>
  foldTenant(
    tenant,
    () => optionalDocumentToIdMapSK,
    () => optionalDocumentToIdMapCZ
  );

export const fileTypes = Object.keys(DocumentMime.keys) as Array<DocumentMime>;
export const proofOfIncomeFileTypes = ["application/pdf" as const];
export const fileTypeExtMap: { [key in DocumentMime]: string } = {
  "application/pdf": ".pdf",
  "application/vnd.ms-outlook": ".msg",
  ".msg": ".msg",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    ".docx",
  "image/jpeg": ".jpg",
  "image/tiff": ".tiff",
};

export const fileTypesExts = fileTypes.map(it => fileTypeExtMap[it]);

export type DocumentManagement = {
  openDialogWithDocument: (
    document: api.Document,
    sectionElementId: Option<string>
  ) => void;
  openRemoveDialog: (document: api.UploadedDocument) => void;
  openViewDialog: (document: api.UploadedDocument) => void;
  openExceptionDialog: (document: api.Document) => void;
  openDeleteRequiredDialog: (
    document: api.Document,
    sectionElementId: Option<string>
  ) => void;
  openRestoreDeleteRequiredDialog: (
    document: api.Document,
    sectionElementId: Option<string>
  ) => void;
  openDeleteRequiredAlreadyUploadedDialog: (
    uploadedDocument: api.UploadedDocument
  ) => void;
  isExceptionProcess: boolean;
  isViewMode: boolean;
};

export type DataVariants =
  | {
      variant: "SectionData";
      data: api.SectionData;
    }
  | {
      variant: "SectionDataArray";
      data: api.SectionData[];
    }
  | {
      variant: "ExtendedSectionDataArray";
      data: api.ExtendedSectionData[];
    };

export type DataVariantsNoArray =
  | {
      variant: "SectionData";
      data: api.SectionData;
    }
  | {
      variant: "ExtendedSectionData";
      data: api.ExtendedSectionData;
    };

const proofOfIncomeDocuments = [
  "3A58A263-20C3-CD1C-AF12-6489C5800000",
  "C6A621E6-E74B-CEC5-B577-6489C5800000",
  "FD0F265F-BFD6-4FCA-8552-7BAF120EF671",
  "D61C4D0F-1CDD-4112-8B98-D682B5CD3245",
  "509C6B80-0000-CE4A-A83D-73041889BD36",
];

export const checkIfProofOfIncomeDocument = (docTypeId: NonEmptyString) => {
  return proofOfIncomeDocuments.some(
    proofOfIncomeDocTypeId => proofOfIncomeDocTypeId === docTypeId
  );
};

const toBase64 = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      typeof reader.result === "string" && resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const decodeFileType = (fileType: string) =>
  pipe(
    DocumentMime.decode(fileType),
    either.mapLeft(() => "UnsupportedDocumentFormat" as const)
  );

export const decodeProofOfIncomeFileType = (fileType: string) =>
  fileType === "application/pdf"
    ? either.right("application/pdf" as const)
    : either.left("POIUnsupportedDocumentFormat" as const);

export function encodeFileToBase64(file: File) {
  return pipe(
    taskEither.tryCatch(
      () => toBase64(file),
      () => "GenericError" as const
    ),
    taskEither.chainW(fileBase64Url =>
      taskEither.fromIOEither(() =>
        pipe(
          fileBase64Url.split(",")[1],
          FileContent.decode,
          either.mapLeft(() => "GenericError" as const)
        )
      )
    )
  );
}

export function mapSubmitDocumentStatus(
  status: api.UploadDocumentOutput["status"]
) {
  switch (status) {
    case "OK": {
      return either.right(undefined);
    }
    case "FILE_SIZE_ERROR": {
      return either.left("UnsupportedDocumentSize" as const);
    }
    case "FORMAT_NOT_SUPPORTED": {
      return either.left("UnsupportedDocumentFormat" as const);
    }
    case "TECHNICAL_ERROR": {
      return either.left("GenericError" as const);
    }
  }
}

export function mapSubmitNoteStatus(
  status: api.DocumentConfirmOutput["status"]
) {
  switch (status) {
    case "OK": {
      return either.right(undefined);
    }
    case "TECHNICAL_ERROR": {
      return either.left("GenericError" as const);
    }
  }
}

export const checkOptionArrayToBeEmpty = (arr: Option<Array<unknown>>) =>
  option.isNone(arr) || arr.value.length === 0;

const checkDocumentObjectsUploaded = (obj: api.SectionData) =>
  option.isNone(obj.sectionDocuments) ||
  obj.sectionDocuments.value.every(
    doc =>
      option.isSome(doc.uploadedDocument) ||
      option.isSome(doc.exceptionComment) ||
      pipe(
        doc.requiredDocument.deletedRequiredDocument,
        option.fold(
          () => false,
          val => val
        )
      )
  );

export const allDocumentsUploaded = (dataVariants: DataVariants) => {
  switch (dataVariants.variant) {
    case "SectionData":
      return checkDocumentObjectsUploaded(dataVariants.data);
    case "SectionDataArray":
      return dataVariants.data.every(checkDocumentObjectsUploaded);
    case "ExtendedSectionDataArray":
      return dataVariants.data.every(
        sec =>
          checkDocumentObjectsUploaded(sec) &&
          sec.subpropertyList.every(checkDocumentObjectsUploaded) &&
          (option.isNone(sec.appraisalSection) ||
            checkDocumentObjectsUploaded(sec.appraisalSection.value))
      );
  }
};
