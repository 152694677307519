import * as t from "io-ts";
import { MoneyAmount, optionFromUndefined } from "../../globalDomain";
import { LoanType, LoanTypeCombined, LoanTypeCommon } from "../Offer/domain";
import { UUID } from "io-ts-types/lib/UUID";
import { RuntimeLocaleKey } from "../../intl";
import { CreditType } from "../domain";

export const FormVisibility = t.keyof({
  None: true,
  NotApplicable: true,
});
export type FormVisibility = t.TypeOf<typeof FormVisibility>;

export type FormSummaryState = {
  amountOfLoan: { [key: number]: number };
  amountOfNonOwnFunds: { [key: number]: number };
  amountOfOwnFunds: { [key: number]: number };
};

export const LoanPurposeOptionsSK = t.keyof({
  CONSTRUCTION: true,
  PURCHASE: true,
  PURCHASE_FROM_DEVELOPER_PROJECT: true,
  RECONSTRUCTION: true,
  REIMBURSEMENT_OF_OWN_FUNDS: true,
  SETTLEMENT_OF_CO_OWNERSHIP: true,
});
export type LoanPurposeOptionsSK = t.TypeOf<typeof LoanPurposeOptionsSK>;

export const LoanPurposeOptionsCZ = t.union([
  t.keyof({
    PAYMENT_OF_COOPERATIVE_SHARE: true,
  }),
  LoanPurposeOptionsSK,
]);

export type LoanPurposeOptionsCZ = t.TypeOf<typeof LoanPurposeOptionsCZ>;

export const LoanPurposeOptions = t.union([
  LoanPurposeOptionsCZ,
  LoanPurposeOptionsSK,
]);
export type LoanPurposeOptions = t.TypeOf<typeof LoanPurposeOptions>;

export const LoanPurpose = t.keyof({
  CONSTRUCTION: true,
  NON_PURPOSE: true,
  PAYMENT_OF_COOPERATIVE_SHARE: true,
  PURCHASE: true,
  PURCHASE_FROM_DEVELOPER_PROJECT: true,
  RECONSTRUCTION: true,
  REFINANCING_OF_LOANS: true,
  REIMBURSEMENT_OF_OWN_FUNDS: true,
  SETTLEMENT_OF_CO_OWNERSHIP: true,
});

export type LoanPurpose = t.TypeOf<typeof LoanPurpose>;

export const PropertyPledgedMap = t.record(t.string, t.boolean);
export type PropertyPledgedMap = t.TypeOf<typeof PropertyPledgedMap>;

export const PurposeAndFinancingInitialInfo = t.intersection([
  t.type({
    allowOtherPurposes: t.boolean,
    loanAmount: MoneyAmount,
    loanPurpose: LoanPurpose,
    loanPurposeLabel: RuntimeLocaleKey,
    propertyPledgedMap: PropertyPledgedMap,
  }),
  t.union([
    t.type({
      loanType: LoanTypeCommon,
    }),
    t.type({
      loanType: LoanTypeCombined,
      nonPurposeAmount: MoneyAmount,
      purposeAmount: MoneyAmount,
    }),
  ]),
]);

export type PurposeAndFinancingInitialInfo = t.TypeOf<
  typeof PurposeAndFinancingInitialInfo
>;

export const DisbursementCZ = t.keyof({
  DepositAccountWithARealEstateAgency: true,
  EscrowAccount: true,
  LawyerCustody: true,
  NotaryCustody: true,
  WithoutCustody: true,
  None: true,
  NotApplicable: true,
});

export const DisbursementSK = t.keyof({
  DirectlyAccordingToThePurpose: true,
  EscrowAccount: true,
  NotaryCustody: true,
  None: true,
  NotApplicable: true,
});

export const Disbursement = t.union([DisbursementCZ, DisbursementSK]);

export type Disbursement = t.TypeOf<typeof Disbursement>;

export const DisbursementBasedOnRegisteredPledge = t.keyof({
  AlreadyRegisteredPledge: true,
  ProposalForRegistrationOfPledge: true,
});
export type DisbursementBasedOnRegisteredPledge = t.TypeOf<
  typeof DisbursementBasedOnRegisteredPledge
>;

export const YesNo = t.keyof({
  No: true,
  Yes: true,
});
export type YesNo = t.TypeOf<typeof YesNo>;

export const PurposeOfOriginalInvestment = t.keyof({
  ConstructionOrReconstruction: true,
  Purchase: true,
});
export type PurposeOfOriginalInvestment = t.TypeOf<
  typeof PurposeOfOriginalInvestment
>;

export const StateOfRegisteredPledge = t.keyof({
  AlreadyRegisteredPledge: true,
  ProposalForRegistrationOfPledge: true,
});
export type StateOfRegisteredPledge = t.TypeOf<typeof StateOfRegisteredPledge>;

export const TypeOfAcquiringOfRentalRights = t.keyof({
  AnotherCooperativeShareBuy: true,
  ObtainingTheRightToLease: true,
});
export type TypeOfAcquiringOfRentalRights = t.TypeOf<
  typeof TypeOfAcquiringOfRentalRights
>;

export const TypeOfSettlementSK = t.keyof({
  SettlementOfInheritanceClaimsOnTheProperty: true,
  SettlementOfPropertyClaims: true,
});
export type TypeOfSettlementSK = t.TypeOf<typeof TypeOfSettlementSK>;

export const TypeOfSettlementCZ = t.keyof({
  SettlementOfCoOwnershipClaims: true,
  SettlementOfInheritanceClaimsOnTheProperty: true,
  SettlementOfPropertyClaims: true,
});
export type TypeOfSettlementCZ = t.TypeOf<typeof TypeOfSettlementCZ>;

export const TypeOfSettlement = t.union([
  TypeOfSettlementCZ,
  TypeOfSettlementSK,
]);
export type TypeOfSettlement = t.TypeOf<typeof TypeOfSettlement>;

export const AdditionalInfoBase = t.type({
  disbursement: optionFromUndefined(t.union([Disbursement, FormVisibility])),
  disbursementBasedOnRegisteredPledge: optionFromUndefined(
    t.union([DisbursementBasedOnRegisteredPledge, FormVisibility])
  ),
  isOwnershipFromHousingType: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  previousClientsAreCurrentBorrowers: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  propertyOwner: optionFromUndefined(t.union([YesNo, FormVisibility])),
  purposeOfOriginalInvestment: optionFromUndefined(
    t.union([PurposeOfOriginalInvestment, FormVisibility])
  ),
  stateOfRegisteredPledge: optionFromUndefined(
    t.union([StateOfRegisteredPledge, FormVisibility])
  ),
  typeOfAcquiringOfRentalRights: optionFromUndefined(
    t.union([TypeOfAcquiringOfRentalRights, FormVisibility])
  ),
  typeOfSettlement: optionFromUndefined(
    t.union([TypeOfSettlement, FormVisibility])
  ),
});
export type AdditionalInfoBase = t.TypeOf<typeof AdditionalInfoBase>;

export const AdditionalInfo = t.type({
  disbursement_0: optionFromUndefined(t.union([Disbursement, FormVisibility])),
  disbursementBasedOnRegisteredPledge_0: optionFromUndefined(
    t.union([DisbursementBasedOnRegisteredPledge, FormVisibility])
  ),
  isOwnershipFromHousingType_0: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  previousClientsAreCurrentBorrowers_0: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  propertyOwner_0: optionFromUndefined(t.union([YesNo, FormVisibility])),
  purposeOfOriginalInvestment_0: optionFromUndefined(
    t.union([PurposeOfOriginalInvestment, FormVisibility])
  ),
  stateOfRegisteredPledge_0: optionFromUndefined(
    t.union([StateOfRegisteredPledge, FormVisibility])
  ),
  typeOfAcquiringOfRentalRights_0: optionFromUndefined(
    t.union([TypeOfAcquiringOfRentalRights, FormVisibility])
  ),
  typeOfSettlement_0: optionFromUndefined(
    t.union([TypeOfSettlement, FormVisibility])
  ),

  disbursement_1: optionFromUndefined(t.union([Disbursement, FormVisibility])),
  disbursementBasedOnRegisteredPledge_1: optionFromUndefined(
    t.union([DisbursementBasedOnRegisteredPledge, FormVisibility])
  ),
  isOwnershipFromHousingType_1: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  previousClientsAreCurrentBorrowers_1: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  propertyOwner_1: optionFromUndefined(t.union([YesNo, FormVisibility])),
  purposeOfOriginalInvestment_1: optionFromUndefined(
    t.union([PurposeOfOriginalInvestment, FormVisibility])
  ),
  stateOfRegisteredPledge_1: optionFromUndefined(
    t.union([StateOfRegisteredPledge, FormVisibility])
  ),
  typeOfAcquiringOfRentalRights_1: optionFromUndefined(
    t.union([TypeOfAcquiringOfRentalRights, FormVisibility])
  ),
  typeOfSettlement_1: optionFromUndefined(
    t.union([TypeOfSettlement, FormVisibility])
  ),

  disbursement_2: optionFromUndefined(t.union([Disbursement, FormVisibility])),
  disbursementBasedOnRegisteredPledge_2: optionFromUndefined(
    t.union([DisbursementBasedOnRegisteredPledge, FormVisibility])
  ),
  isOwnershipFromHousingType_2: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  previousClientsAreCurrentBorrowers_2: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  propertyOwner_2: optionFromUndefined(t.union([YesNo, FormVisibility])),
  purposeOfOriginalInvestment_2: optionFromUndefined(
    t.union([PurposeOfOriginalInvestment, FormVisibility])
  ),
  stateOfRegisteredPledge_2: optionFromUndefined(
    t.union([StateOfRegisteredPledge, FormVisibility])
  ),
  typeOfAcquiringOfRentalRights_2: optionFromUndefined(
    t.union([TypeOfAcquiringOfRentalRights, FormVisibility])
  ),
  typeOfSettlement_2: optionFromUndefined(
    t.union([TypeOfSettlement, FormVisibility])
  ),

  disbursement_3: optionFromUndefined(t.union([Disbursement, FormVisibility])),
  disbursementBasedOnRegisteredPledge_3: optionFromUndefined(
    t.union([DisbursementBasedOnRegisteredPledge, FormVisibility])
  ),
  isOwnershipFromHousingType_3: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  previousClientsAreCurrentBorrowers_3: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  propertyOwner_3: optionFromUndefined(t.union([YesNo, FormVisibility])),
  purposeOfOriginalInvestment_3: optionFromUndefined(
    t.union([PurposeOfOriginalInvestment, FormVisibility])
  ),
  stateOfRegisteredPledge_3: optionFromUndefined(
    t.union([StateOfRegisteredPledge, FormVisibility])
  ),
  typeOfAcquiringOfRentalRights_3: optionFromUndefined(
    t.union([TypeOfAcquiringOfRentalRights, FormVisibility])
  ),
  typeOfSettlement_3: optionFromUndefined(
    t.union([TypeOfSettlement, FormVisibility])
  ),

  disbursement_4: optionFromUndefined(t.union([Disbursement, FormVisibility])),
  disbursementBasedOnRegisteredPledge_4: optionFromUndefined(
    t.union([DisbursementBasedOnRegisteredPledge, FormVisibility])
  ),
  isOwnershipFromHousingType_4: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  previousClientsAreCurrentBorrowers_4: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  propertyOwner_4: optionFromUndefined(t.union([YesNo, FormVisibility])),
  purposeOfOriginalInvestment_4: optionFromUndefined(
    t.union([PurposeOfOriginalInvestment, FormVisibility])
  ),
  stateOfRegisteredPledge_4: optionFromUndefined(
    t.union([StateOfRegisteredPledge, FormVisibility])
  ),
  typeOfAcquiringOfRentalRights_4: optionFromUndefined(
    t.union([TypeOfAcquiringOfRentalRights, FormVisibility])
  ),
  typeOfSettlement_4: optionFromUndefined(
    t.union([TypeOfSettlement, FormVisibility])
  ),

  disbursement_5: optionFromUndefined(t.union([Disbursement, FormVisibility])),
  disbursementBasedOnRegisteredPledge_5: optionFromUndefined(
    t.union([DisbursementBasedOnRegisteredPledge, FormVisibility])
  ),
  isOwnershipFromHousingType_5: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  previousClientsAreCurrentBorrowers_5: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  propertyOwner_5: optionFromUndefined(t.union([YesNo, FormVisibility])),
  purposeOfOriginalInvestment_5: optionFromUndefined(
    t.union([PurposeOfOriginalInvestment, FormVisibility])
  ),
  stateOfRegisteredPledge_5: optionFromUndefined(
    t.union([StateOfRegisteredPledge, FormVisibility])
  ),
  typeOfAcquiringOfRentalRights_5: optionFromUndefined(
    t.union([TypeOfAcquiringOfRentalRights, FormVisibility])
  ),
  typeOfSettlement_5: optionFromUndefined(
    t.union([TypeOfSettlement, FormVisibility])
  ),

  disbursement_6: optionFromUndefined(t.union([Disbursement, FormVisibility])),
  disbursementBasedOnRegisteredPledge_6: optionFromUndefined(
    t.union([DisbursementBasedOnRegisteredPledge, FormVisibility])
  ),
  isOwnershipFromHousingType_6: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  previousClientsAreCurrentBorrowers_6: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  propertyOwner_6: optionFromUndefined(t.union([YesNo, FormVisibility])),
  purposeOfOriginalInvestment_6: optionFromUndefined(
    t.union([PurposeOfOriginalInvestment, FormVisibility])
  ),
  stateOfRegisteredPledge_6: optionFromUndefined(
    t.union([StateOfRegisteredPledge, FormVisibility])
  ),
  typeOfAcquiringOfRentalRights_6: optionFromUndefined(
    t.union([TypeOfAcquiringOfRentalRights, FormVisibility])
  ),
  typeOfSettlement_6: optionFromUndefined(
    t.union([TypeOfSettlement, FormVisibility])
  ),

  disbursement_7: optionFromUndefined(t.union([Disbursement, FormVisibility])),
  disbursementBasedOnRegisteredPledge_7: optionFromUndefined(
    t.union([DisbursementBasedOnRegisteredPledge, FormVisibility])
  ),
  isOwnershipFromHousingType_7: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  previousClientsAreCurrentBorrowers_7: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  propertyOwner_7: optionFromUndefined(t.union([YesNo, FormVisibility])),
  purposeOfOriginalInvestment_7: optionFromUndefined(
    t.union([PurposeOfOriginalInvestment, FormVisibility])
  ),
  stateOfRegisteredPledge_7: optionFromUndefined(
    t.union([StateOfRegisteredPledge, FormVisibility])
  ),
  typeOfAcquiringOfRentalRights_7: optionFromUndefined(
    t.union([TypeOfAcquiringOfRentalRights, FormVisibility])
  ),
  typeOfSettlement_7: optionFromUndefined(
    t.union([TypeOfSettlement, FormVisibility])
  ),

  disbursement_8: optionFromUndefined(t.union([Disbursement, FormVisibility])),
  disbursementBasedOnRegisteredPledge_8: optionFromUndefined(
    t.union([DisbursementBasedOnRegisteredPledge, FormVisibility])
  ),
  isOwnershipFromHousingType_8: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  previousClientsAreCurrentBorrowers_8: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  propertyOwner_8: optionFromUndefined(t.union([YesNo, FormVisibility])),
  purposeOfOriginalInvestment_8: optionFromUndefined(
    t.union([PurposeOfOriginalInvestment, FormVisibility])
  ),
  stateOfRegisteredPledge_8: optionFromUndefined(
    t.union([StateOfRegisteredPledge, FormVisibility])
  ),
  typeOfAcquiringOfRentalRights_8: optionFromUndefined(
    t.union([TypeOfAcquiringOfRentalRights, FormVisibility])
  ),
  typeOfSettlement_8: optionFromUndefined(
    t.union([TypeOfSettlement, FormVisibility])
  ),

  disbursement_9: optionFromUndefined(t.union([Disbursement, FormVisibility])),
  disbursementBasedOnRegisteredPledge_9: optionFromUndefined(
    t.union([DisbursementBasedOnRegisteredPledge, FormVisibility])
  ),
  isOwnershipFromHousingType_9: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  previousClientsAreCurrentBorrowers_9: optionFromUndefined(
    t.union([YesNo, FormVisibility])
  ),
  propertyOwner_9: optionFromUndefined(t.union([YesNo, FormVisibility])),
  purposeOfOriginalInvestment_9: optionFromUndefined(
    t.union([PurposeOfOriginalInvestment, FormVisibility])
  ),
  stateOfRegisteredPledge_9: optionFromUndefined(
    t.union([StateOfRegisteredPledge, FormVisibility])
  ),
  typeOfAcquiringOfRentalRights_9: optionFromUndefined(
    t.union([TypeOfAcquiringOfRentalRights, FormVisibility])
  ),
  typeOfSettlement_9: optionFromUndefined(
    t.union([TypeOfSettlement, FormVisibility])
  ),
});
export type AdditionalInfo = t.TypeOf<typeof AdditionalInfo>;

export const PurposeAndFinancingFormStateBase = t.type({
  loanPurpose_0: optionFromUndefined(LoanPurpose),
  amountOfLoan_0: optionFromUndefined(t.number),
  amountOfOwnFunds_0: optionFromUndefined(t.number),
  amountOfNonOwnFunds_0: optionFromUndefined(t.number),
  financedProperty_0: optionFromUndefined(t.string),

  loanPurpose_1: optionFromUndefined(LoanPurpose),
  amountOfLoan_1: optionFromUndefined(t.number),
  amountOfOwnFunds_1: optionFromUndefined(t.number),
  amountOfNonOwnFunds_1: optionFromUndefined(t.number),
  financedProperty_1: optionFromUndefined(t.string),

  loanPurpose_2: optionFromUndefined(LoanPurpose),
  amountOfLoan_2: optionFromUndefined(t.number),
  amountOfOwnFunds_2: optionFromUndefined(t.number),
  amountOfNonOwnFunds_2: optionFromUndefined(t.number),
  financedProperty_2: optionFromUndefined(t.string),

  loanPurpose_3: optionFromUndefined(LoanPurpose),
  amountOfLoan_3: optionFromUndefined(t.number),
  amountOfOwnFunds_3: optionFromUndefined(t.number),
  amountOfNonOwnFunds_3: optionFromUndefined(t.number),
  financedProperty_3: optionFromUndefined(t.string),

  loanPurpose_4: optionFromUndefined(LoanPurpose),
  amountOfLoan_4: optionFromUndefined(t.number),
  amountOfOwnFunds_4: optionFromUndefined(t.number),
  amountOfNonOwnFunds_4: optionFromUndefined(t.number),
  financedProperty_4: optionFromUndefined(t.string),

  loanPurpose_5: optionFromUndefined(LoanPurpose),
  amountOfLoan_5: optionFromUndefined(t.number),
  amountOfOwnFunds_5: optionFromUndefined(t.number),
  amountOfNonOwnFunds_5: optionFromUndefined(t.number),
  financedProperty_5: optionFromUndefined(t.string),

  loanPurpose_6: optionFromUndefined(LoanPurpose),
  amountOfLoan_6: optionFromUndefined(t.number),
  amountOfOwnFunds_6: optionFromUndefined(t.number),
  amountOfNonOwnFunds_6: optionFromUndefined(t.number),
  financedProperty_6: optionFromUndefined(t.string),

  loanPurpose_7: optionFromUndefined(LoanPurpose),
  amountOfLoan_7: optionFromUndefined(t.number),
  amountOfOwnFunds_7: optionFromUndefined(t.number),
  amountOfNonOwnFunds_7: optionFromUndefined(t.number),
  financedProperty_7: optionFromUndefined(t.string),

  loanPurpose_8: optionFromUndefined(LoanPurpose),
  amountOfLoan_8: optionFromUndefined(t.number),
  amountOfOwnFunds_8: optionFromUndefined(t.number),
  amountOfNonOwnFunds_8: optionFromUndefined(t.number),
  financedProperty_8: optionFromUndefined(t.string),

  loanPurpose_9: optionFromUndefined(LoanPurpose),
  amountOfLoan_9: optionFromUndefined(t.number),
  amountOfOwnFunds_9: optionFromUndefined(t.number),
  amountOfNonOwnFunds_9: optionFromUndefined(t.number),
  financedProperty_9: optionFromUndefined(t.string),
});

export const PurposeAndFinancingFormState = t.intersection([
  PurposeAndFinancingFormStateBase,
  AdditionalInfo,
]);
export type PurposeAndFinancingFormState = t.TypeOf<
  typeof PurposeAndFinancingFormState
>;

export const Financing = t.type({
  amountOfLoan: optionFromUndefined(MoneyAmount),
  amountOfNonOwnFunds: optionFromUndefined(MoneyAmount),
  amountOfOwnFunds: optionFromUndefined(MoneyAmount),
});
export type Financing = t.TypeOf<typeof Financing>;

export const Purpose = t.type({
  additionalInfo: optionFromUndefined(AdditionalInfoBase),
  currentBalance: optionFromUndefined(MoneyAmount),
  financedProperty: optionFromUndefined(t.string),
  financing: Financing,
  isFinancedPropertySameAsPledged: t.boolean,
  isPropertiesDefined: t.boolean,
  loanPurpose: LoanPurpose,
  loanType: LoanType,
  mainPurpose: t.boolean,
  predefinedPurpose: t.boolean,
  provider: optionFromUndefined(t.string),
  purposeId: optionFromUndefined(UUID),
  creditType: optionFromUndefined(CreditType),
  errorSave: t.boolean,
  errorDelete: t.boolean,
  completed: t.boolean,
});
export type Purpose = t.TypeOf<typeof Purpose>;

export const SavePurposeInput = t.intersection([
  t.type({
    purposeId: optionFromUndefined(UUID),
    loanPurpose: LoanPurpose,
    amountOfLoan: MoneyAmount,
    amountOfNonOwnFunds: optionFromUndefined(MoneyAmount),
    amountOfOwnFunds: optionFromUndefined(MoneyAmount),
    financedProperty: optionFromUndefined(t.string),
  }),
  AdditionalInfoBase,
]);
export type SavePurposeInput = t.TypeOf<typeof SavePurposeInput>;

export const SavePurposeInputArray = t.type({
  purposes: t.array(SavePurposeInput),
});
export type SavePurposeInputArray = t.TypeOf<typeof SavePurposeInputArray>;

export const IndexAmountMap = t.record(t.number, t.number);
export type IndexAmountMap = t.TypeOf<typeof IndexAmountMap>;

export const IndexStateOfRegisteredPledgeMap = t.record(
  t.number,
  t.union([StateOfRegisteredPledge, FormVisibility])
);
export type IndexStateOfRegisteredPledgeMap = t.TypeOf<
  typeof IndexStateOfRegisteredPledgeMap
>;

export const IndexYesNoMap = t.record(
  t.number,
  t.union([YesNo, FormVisibility])
);
export type IndexYesNoMap = t.TypeOf<typeof IndexYesNoMap>;

export const IndexStringMap = t.record(
  t.number,
  t.union([t.string, FormVisibility])
);
export type IndexStringMap = t.TypeOf<typeof IndexStringMap>;

export const IndexDisbursementMap = t.record(
  t.number,
  t.union([Disbursement, FormVisibility])
);
export type IndexDisbursementMap = t.TypeOf<typeof IndexDisbursementMap>;

export const IndexPurposeOfOriginalInvestmentMap = t.record(
  t.number,
  t.union([PurposeOfOriginalInvestment, FormVisibility])
);
export type IndexPurposeOfOriginalInvestmentMap = t.TypeOf<
  typeof IndexPurposeOfOriginalInvestmentMap
>;

export const IndexTypeOfAcquiringOfRentalRightsMap = t.record(
  t.number,
  t.union([TypeOfAcquiringOfRentalRights, FormVisibility])
);
export type IndexTypeOfAcquiringOfRentalRightsMap = t.TypeOf<
  typeof IndexTypeOfAcquiringOfRentalRightsMap
>;

export const IndexDisbursementBasedOnRegisteredPledgeMap = t.record(
  t.number,
  t.union([DisbursementBasedOnRegisteredPledge, FormVisibility])
);
export type IndexDisbursementBasedOnRegisteredPledgeMap = t.TypeOf<
  typeof IndexDisbursementBasedOnRegisteredPledgeMap
>;

export const IndexTypeOfSettlementMap = t.record(
  t.number,
  t.union([TypeOfSettlement, FormVisibility])
);
export type IndexTypeOfSettlementMap = t.TypeOf<
  typeof IndexTypeOfSettlementMap
>;

export const PurposeOverview = t.type({
  amountOfLoan: MoneyAmount,
  amountOfOwnFunds: MoneyAmount,
  amountOfNonOwnFunds: MoneyAmount,
  totalSum: MoneyAmount,
  amountFromOffer: MoneyAmount,
  sameAmount: t.boolean,
});

export type PurposeOverview = t.TypeOf<typeof PurposeOverview>;
export type FormVariant = "Predefined" | "Additional";
