import { option } from "fp-ts";
import { constVoid } from "fp-ts/function";
import { Box, Space, Children } from "design-system";
import { palette } from "design-system/lib/styleConstants";

import { MainLayout } from "../Common/MainLayout/MainLayout";

type Props = {
  children: Children;
};

export function LoginLandingWrapper(props: Props) {
  return (
    <MainLayout
      title={option.none}
      backgroundImageURL={option.none}
      onLogoClick={option.none}
      iconAction={option.none}
      onStepClick={constVoid}
      steps={option.none}
      contentBackground={palette.background}
    >
      <Box column grow vAlignContent="top">
        <Space units={10} />
        {props.children}
      </Box>
    </MainLayout>
  );
}
