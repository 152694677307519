import {
  Box,
  Form,
  FormRow,
  TextField,
  PasswordField,
  FormSection,
  Card,
  useForm,
  validators,
  formError,
  formWarning,
  FormError,
} from "design-system";

import { option, nonEmptyArray } from "fp-ts";
import { LocaleKey, useFormatMessage } from "../intl";
import { ForgotPasswordLink } from "./ForgotPasswordLink";
import { LocaleSelector } from "./LocaleSelector";
import { useValidators } from "../Common/useValidators";
import { TaskEither } from "fp-ts/TaskEither";
import { constVoid, pipe } from "fp-ts/function";
import { usePortalStatusContext } from "../PortalStatusContext";
import { useEffect } from "react";
import { foldPortalStatus } from "../globalDomain";
import { Option } from "fp-ts/Option";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";

export type LoginCredentials = { username: string; password: string };

export type LoginFormError = LoginWarning | LoginError;
type LoginWarning = {
  type: "warning";
  error: LocaleKey;
  action: Option<LoginFormErrorAction>;
};
type LoginError = {
  type: "error";
  error: LocaleKey;
  action: Option<LoginFormErrorAction>;
};
export type LoginFormErrorAction = {
  label: LocaleKey;
  action: () => unknown;
};

export function loginFormError(
  message: LocaleKey,
  action?: LoginFormErrorAction
): LoginFormError {
  return { type: "error", error: message, action: option.fromNullable(action) };
}

export function loginFormWarning(
  message: LocaleKey,
  action?: LoginFormErrorAction
): LoginFormError {
  return {
    type: "warning",
    error: message,
    action: option.fromNullable(action),
  };
}

type Props = {
  onThirdPartyPasswordSetup?: () => unknown;
  onLogin: (
    credentials: LoginCredentials
  ) => TaskEither<LoginFormError, unknown>;
  disabled?: boolean;
};

export function Login(props: Props) {
  const formatMessage = useFormatMessage();

  const { maxLength, validEmail } = useValidators();

  const is3P = !!props.onThirdPartyPasswordSetup;

  const { disabled } = props;

  useEffect(() => {
    //workaround for when we arrived in login page because of the IdleGuard shown on top of another dialog
    document.body.style.overflow = "";
  }, []);

  const { fieldProps, handleSubmit, formErrors, handleReset } = useForm(
    {
      initialValues: {
        username: "",
        password: "",
      },
      fieldValidators: () => ({
        username: is3P
          ? validators.inSequence(
              validators.nonBlankString(
                formatMessage("3PLogin.usernameRequiredError")
              ),
              maxLength(254),
              validEmail
            )
          : validators.inSequence(
              validators.nonBlankString(
                formatMessage("Login.usernameRequiredError")
              ),
              maxLength(254)
            ),
        password: validators.inSequence(
          validators.nonEmptyString(
            formatMessage("Login.passwordRequiredError")
          ),
          maxLength(254)
        ),
      }),
    },
    {
      onSubmit: props.onLogin,
    }
  );

  const { portalStatus } = usePortalStatusContext();

  useEffect(() => {
    foldPortalStatus(portalStatus, {
      onActive: handleReset,
      onInitial: constVoid,
      onNone: constVoid,
      onScheduled: constVoid,
    });
  }, [portalStatus.type]);

  function formatLoginError(error: LoginFormError): NonEmptyArray<FormError> {
    return nonEmptyArray.of(
      (() => {
        switch (error.type) {
          case "error":
            return formError(
              formatMessage(error.error),
              pipe(
                error.action,
                option.map(action => ({
                  ...action,
                  label: formatMessage(action.label),
                })),
                option.toUndefined
              )
            );
          case "warning":
            return formWarning(
              formatMessage(error.error),
              pipe(
                error.action,
                option.map(action => ({
                  ...action,
                  label: formatMessage(action.label),
                })),
                option.toUndefined
              )
            );
        }
      })()
    );
  }

  return (
    <Box grow shrink hAlignContent="center" vAlignContent="center">
      <Box shrink column width="400px">
        <Card>
          <Form
            grow
            shrink
            submitButton={{
              action: handleSubmit,
              label: formatMessage("Login.submit"),
              disabled: disabled,
            }}
          >
            <FormSection
              errors={pipe(formErrors, option.map(formatLoginError))}
            >
              <FormRow type="full">
                <LocaleSelector />
              </FormRow>
              <FormRow type="full">
                <TextField
                  {...fieldProps("username")}
                  label={
                    is3P
                      ? formatMessage("3PLogin.usernameLabel")
                      : formatMessage("Login.usernameLabel")
                  }
                  placeholder={
                    is3P
                      ? formatMessage("3PLogin.usernamePlaceholder")
                      : formatMessage("Login.usernamePlaceholder")
                  }
                  disabled={disabled}
                />
              </FormRow>
              <FormRow type="full">
                <PasswordField
                  {...fieldProps("password")}
                  label={
                    is3P
                      ? formatMessage("3PLogin.passwordLabel")
                      : formatMessage("Login.passwordLabel")
                  }
                  placeholder={formatMessage("Login.passwordPlaceholder")}
                  showHideButton={option.some({
                    hideLabel: formatMessage("Hide"),
                    showLabel: formatMessage("Show"),
                  })}
                  disabled={disabled}
                />
              </FormRow>
              {!disabled && (
                <FormRow type="full">
                  <ForgotPasswordLink
                    onClick={props.onThirdPartyPasswordSetup}
                  />
                </FormRow>
              )}
            </FormSection>
          </Form>
        </Card>
      </Box>
    </Box>
  );
}
