import { array, option } from "fp-ts";
import { useFormatMessage } from "../intl";
import { LocalizedString, useLoadingStatusRead } from "design-system";
import { pipe } from "fp-ts/function";
import { useAppContext } from "../useAppContext";
import { DropdownField } from "../Common/DropdownField/DropdownField";

import { SupportedLocales } from "../globalDomain";

type Props = {
  width?: string;
};

export const LocaleSelector = (props: Props) => {
  const formatMessage = useFormatMessage();

  const { isSubmitting } = useLoadingStatusRead();

  const context = useAppContext();

  const {
    apiParameters: { tenant },
  } = useAppContext();

  const orderedLocales: Array<SupportedLocales> =
    tenant === "CZ" ? ["en", "cs"] : ["en", "sk"];

  const getLabelForLocale = (loc: SupportedLocales): LocalizedString => {
    switch (loc) {
      case "en":
        return formatMessage("Languages.english");
      case "cs":
        return formatMessage("Languages.czech");
      case "sk":
        return formatMessage("Languages.slovak");
    }
  };

  const getOptionFromValue = (value: SupportedLocales) => {
    return {
      value: value,
      label: getLabelForLocale(value),
    };
  };

  const localesOptions = orderedLocales.map(getOptionFromValue);

  return (
    <DropdownField
      name="localeSelector"
      placeholder={formatMessage("Languages.select")}
      options={localesOptions}
      value={pipe(
        localesOptions,
        array.findFirst(option => option.value === context.locale)
      )}
      onChange={value => {
        if (option.isSome(value)) {
          context.setLocale(value.value.value);
        }
      }}
      onBlur={() => {}}
      clearable={false}
      searchable={false}
      issues={option.none}
      label={formatMessage("Languages.select")}
      disabled={isSubmitting}
      width={props.width}
    />
  );
};
