import { Box, DropdownOption, Stack } from "design-system";
import { LiabilitySection } from "./LiabilitySection";
import { array, option } from "fp-ts";
import { pipe, constFalse, constTrue, constVoid } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import {
  CreditCard,
  LoanList,
  ReworkConsumerLoanOrMortgage,
  ReworkCreditCard,
} from "../../domain";
import { useFormatMessage } from "../../../intl";
import { SelectedApplicant } from "../../mortgageDashboardUtils";
import { EditSection } from "./EditSection";
import { CreditCardForm } from "./CreditCardForm";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { AmountLimit } from "../../api";
import { LiabilitiesVariant } from "./Liabilities";

type Props = {
  isInteractingWithAnyForm: boolean;
  creditCardsList: Array<CreditCard>;
  selectedApplicant: SelectedApplicant;
  isAdding: boolean;
  isACPhase: boolean;
  isViewMode: boolean;
  editingRecordID: Option<NonEmptyString>;

  onEdit: (creditCard: CreditCard) => unknown;
  onCancel: () => unknown;
  onAdding: (type: keyof LoanList) => unknown;
  onAdd: (creditCard: CreditCard) => unknown;
  onRemove: (recordId: NonEmptyString) => unknown;
  onEditing: (recordId: NonEmptyString) => unknown;
  providersList: DropdownOption<NonEmptyString>[];
  amountLimits: {
    [key in "ccAndOvdCurrentBalance" | "ccAndOvdLimit"]: AmountLimit;
  };
  variant: LiabilitiesVariant;
  getReworkCreditCard: (recordId: NonEmptyString) => Option<ReworkCreditCard>;
  getReworkConsumerLoan: (
    recordId: NonEmptyString
  ) => Option<ReworkConsumerLoanOrMortgage>;
};

export function CreditCardStack(props: Props) {
  const formatMessage = useFormatMessage();

  const { left: creditCardsList, right: manuallyAddedCreditCardsList } = pipe(
    props.creditCardsList,
    array.partition(creditCard => creditCard.manuallyAdded)
  );

  const creditCardsFormat = (credit: CreditCard) => {
    const reworkCreditCard = props.getReworkCreditCard(credit.recordId);

    return (
      <Box grow shrink column key={credit.recordId}>
        <CreditCardForm
          initialValues={option.some(credit)}
          onSave={props.onEdit}
          onCancel={props.onCancel}
          onEdit={() => props.onEditing(credit.recordId)}
          onRemove={() => props.onRemove(credit.recordId)}
          isACPhase={props.isACPhase}
          isInteractingWithAnyForm={props.isInteractingWithAnyForm}
          selectedApplicant={props.selectedApplicant}
          providersList={props.providersList}
          amountLimits={props.amountLimits}
          edit={
            !props.isViewMode &&
            pipe(
              props.editingRecordID,
              option.filter(id => id === credit.recordId),
              option.fold(constFalse, constTrue)
            )
          }
          isViewMode={props.isViewMode}
          variant={props.variant}
          reworkCreditCard={reworkCreditCard}
        />
      </Box>
    );
  };

  const newCreditCardData = {
    label: formatMessage("Mortgage.CBResults.Liabilities.addCreditCards"),
    action: () => props.onAdding("creditCardsList"),
    disabled: props.isInteractingWithAnyForm,
  };

  return (
    <Stack key="creditCardsSection" units={4} column>
      <LiabilitySection
        key="creditCards"
        title={formatMessage("Mortgage.CBResults.Liabilities.creditCards")}
        children={creditCardsList.map(creditCardsFormat)}
        addNew={pipe(
          newCreditCardData,
          option.fromPredicate(
            () =>
              !props.isViewMode &&
              !props.isAdding &&
              manuallyAddedCreditCardsList.length === 0
          )
        )}
      />
      {manuallyAddedCreditCardsList.length > 0 && (
        <LiabilitySection
          key="manuallyAddedCreditCards"
          title={formatMessage(
            "Mortgage.CBResults.Liabilities.manuallyAddedCreditCards"
          )}
          children={manuallyAddedCreditCardsList.map(creditCardsFormat)}
          addNew={pipe(
            newCreditCardData,
            option.fromPredicate(() => !props.isViewMode && !props.isAdding)
          )}
        />
      )}
      {props.isAdding && (
        <EditSection
          title={formatMessage("Liabilities.AddCreditCard.formSectionTitle")}
        >
          <CreditCardForm
            initialValues={option.none}
            onSave={props.onAdd}
            onCancel={props.onCancel}
            onEdit={constVoid}
            onRemove={constVoid}
            isACPhase={props.isACPhase}
            isInteractingWithAnyForm={props.isInteractingWithAnyForm}
            selectedApplicant={props.selectedApplicant}
            providersList={props.providersList}
            amountLimits={props.amountLimits}
            edit={!props.isViewMode}
            isViewMode={props.isViewMode}
            variant={props.variant}
            reworkCreditCard={option.none}
          />
        </EditSection>
      )}
    </Stack>
  );
}
