import {
  Box,
  Button,
  Form,
  TextField,
  Space,
  DropdownOption,
  CheckboxField,
  FormSection,
  useLoadingStatusRead,
  Card,
  ComputedFieldProps,
} from "design-system";
import { useFormatMessage } from "../intl";
import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { ReasonNote } from "./domain";
import { useAppContext } from "../useAppContext";

import { pipe } from "fp-ts/function";
import { dropdownOptionToValue } from "../Common/selectDropdownOption";
import * as kycApi from "./api";
import { DropdownField } from "../Common/DropdownField/DropdownField";

export type TaxPayerCountryFormModel = {
  country: Option<DropdownOption<kycApi.Country>>;
  TIN: string;
  note: Option<DropdownOption<ReasonNote>>;
  countryRequiresTIN: boolean;
  noTIN: boolean;
};

export function TaxPayerCountryForm(props: {
  namePrefix: string;
  onRemove: () => unknown;
  showRemoveButton: boolean;
  onChangeValues: (value: TaxPayerCountryFormModel) => void;
  fieldProps: <K extends keyof TaxPayerCountryFormModel>(
    name: K
  ) => ComputedFieldProps<TaxPayerCountryFormModel[K]>;
  noteOptions: DropdownOption<ReasonNote>[];
  countryOptions: DropdownOption<kycApi.Country>[];
}) {
  const formatMessage = useFormatMessage();
  const { isSubmitting } = useLoadingStatusRead();

  const {
    config: { tinByCountryURL },
  } = useAppContext();

  const isTinRequired = props.fieldProps("countryRequiresTIN").value;

  function resetForm(newCountry: Option<DropdownOption<kycApi.Country>>) {
    props.onChangeValues({
      country: newCountry,
      TIN: "",
      note: option.none,
      countryRequiresTIN: pipe(
        newCountry,
        dropdownOptionToValue,
        option.exists(c => c.TIN)
      ),
      noTIN: false,
    });
  }

  const handleOnCountryChange = (
    newCountry: Option<DropdownOption<kycApi.Country>>
  ) => {
    resetForm(newCountry);
  };

  const handleChangeNoTin = (noTIN: boolean) => {
    props.onChangeValues({
      TIN: "",
      note: option.none,
      country: props.fieldProps("country").value,
      countryRequiresTIN: props.fieldProps("countryRequiresTIN").value,
      noTIN: noTIN,
    });
  };

  return (
    <Card>
      <Box column grow shrink>
        <Box hAlignContent="right">
          {props.showRemoveButton && (
            <Button
              variant="text"
              size="default"
              action={props.onRemove}
              label={formatMessage("KYC.TaxPayerCountryForm.removeCountry")}
              disabled={isSubmitting}
            />
          )}
        </Box>
        <Space units={2} />
        <Form>
          <FormSection>
            <DropdownField
              {...props.fieldProps("country")}
              onChange={handleOnCountryChange}
              label={formatMessage("KYC.TaxPayerCountryForm.countryLabel")}
              placeholder={formatMessage(
                "KYC.TaxPayerCountryForm.countryPlaceholder"
              )}
              options={props.countryOptions}
              disabled={isSubmitting}
              clearable
              searchable
            />
            <TextField
              {...props.fieldProps("TIN")}
              label={formatMessage("KYC.TaxPayerCountryForm.tinLabel")}
              placeholder={formatMessage(
                "KYC.TaxPayerCountryForm.tinPlaceholder"
              )}
              cta={{
                label: formatMessage("MoreInfo"),
                action: () => window.open(tinByCountryURL, "_blank"),
              }}
              disabled={
                props.fieldProps("noTIN").value ||
                !isTinRequired ||
                isSubmitting
              }
            />
            {isTinRequired && (
              <CheckboxField
                {...props.fieldProps("noTIN")}
                onChange={handleChangeNoTin}
                label={formatMessage("KYC.TaxPayerCountryForm.doNotShareTin")}
                disabled={isSubmitting}
                multiline
              />
            )}
            {isTinRequired && props.fieldProps("noTIN").value && (
              <>
                <Space units={2} />
                <DropdownField
                  {...props.fieldProps("note")}
                  label={formatMessage(
                    "KYC.TaxPayerCountryForm.provideReasonLabel"
                  )}
                  placeholder={formatMessage(
                    "KYC.TaxPayerCountryForm.provideReasonPlaceholder"
                  )}
                  options={props.noteOptions}
                  searchable={false}
                  clearable
                  disabled={isSubmitting}
                />
              </>
            )}
          </FormSection>
        </Form>
      </Box>
    </Card>
  );
}
