import * as t from "io-ts";
import { Eq } from "fp-ts/Eq";
import { eq, option } from "fp-ts";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { apiCall } from "../APICall";
import { optionFromUndefined } from "../globalDomain";
import { UUID } from "io-ts-types/lib/UUID";

const LoginInput = t.type(
  {
    username: t.string,
    password: t.string,
    anonymousToken: optionFromUndefined(NonEmptyString),
  },
  "LoginInput"
);
interface LoginInput extends t.TypeOf<typeof LoginInput> {}
const eqLoginInput: Eq<LoginInput> = eq.getStructEq({
  username: eq.eqString,
  password: eq.eqString,
  anonymousToken: option.getEq(eq.eqString),
});
export const LoginOutput = t.type(
  { token: NonEmptyString, refreshToken: NonEmptyString, flowId: UUID },
  "LoginOutput"
);
export interface LoginOutput extends t.TypeOf<typeof LoginOutput> {}
const LoginAPIError = t.type({
  id: t.literal("Unauthorized"),
});
export interface LoginAPIError extends t.TypeOf<typeof LoginAPIError> {}

export const login = apiCall({
  inputEq: eqLoginInput,
  path: ["authorization", "banker", "token"],
  inputCodec: LoginInput,
  outputCodec: LoginOutput,
  errorCodec: LoginAPIError,
});
