import { NonNegativeInteger, PositiveInteger } from "design-system";
import { array, option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";
import { ApplicantRecord } from "../domain";
import {
  SelectedApplicant,
  selectedCoApplicant,
  selectedMainApplicant,
} from "../mortgageDashboardUtils";

/* There isn't a better-provided value to identify the main applicant, 
  applicantIndex value eq "0" should always represent the main applicant. 
  Currently positional access at first element guarantees the main applicant in many parts of codebase
  (investigate if BFF, in the future, will provide a properly value or fields to detect the main applicant from list)
*/
export function getMainApplicant<T>(applicants: ApplicantRecord<T>): Option<T> {
  return pipe(
    applicants,
    array.findFirst(applicant => applicant.applicantIndex === 0),
    option.map(({ data }) => data)
  );
}

export function getCurrentApplicant<T>(
  selectedApplicant: SelectedApplicant,
  applicants: ApplicantRecord<T>
): Option<T> {
  return pipe(
    applicants,
    array.findFirst(
      applicant => applicant.applicantIndex === selectedApplicant.index
    ),
    option.map(({ data }) => data)
  );
}

export function getCoApplicant<T>(applicants: ApplicantRecord<T>): Option<T> {
  return pipe(
    applicants,
    array.findFirst(applicant => applicant.applicantIndex === 1),
    option.map(({ data }) => data)
  );
}

export function selectedApplicantFromIndex(
  index: NonNegativeInteger
): SelectedApplicant {
  return PositiveInteger.is(index)
    ? selectedCoApplicant(index)
    : selectedMainApplicant;
}

export function selectedApplicantFromStringIndex(
  applicantIndex: String
): Option<SelectedApplicant> {
  return pipe(
    applicantIndex,
    NumberFromString.pipe(NonNegativeInteger).decode,
    option.fromEither,
    option.map(selectedApplicantFromIndex)
  );
}
