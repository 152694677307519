import { NonNegativeInteger } from "design-system";
import * as t from "io-ts";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { UUID } from "io-ts-types/lib/UUID";
import {
  MonthYear,
  optional,
  optionFromUndefined,
  Tenant,
} from "../../../globalDomain";

/**
 * IncomeSource
 */
export const IncomeSource = t.keyof(
  {
    Employed: true,
    SpecialType: true,
    Freelancer: true,
    CompanyOwner: true,
    TradesmanCoOperatingPerson: true,
    BrokerOfUCB: true,
  },
  "IncomeSource"
);

export const IncomeSourceForFreelancerOrTradesman = t.keyof(
  {
    Employed: true,
    SpecialType: true,
    CompanyOwner: true,
    BrokerOfUCB: true,
  },
  "IncomeSourceForFreelancerOrTradesman"
);

export type IncomeSource = t.TypeOf<typeof IncomeSource>;

/**
 * PaymentMethod
 */
export const PaymentMethodSK = t.keyof(
  {
    SalarySentUCB: true,
    SalarySentOtherBank: true,
    PaymentInCash: true,
  },
  "PaymentMethodSK"
);

export type PaymentMethodSK = t.TypeOf<typeof PaymentMethodSK>;

export const PaymentMethodCZ = t.keyof(
  {
    Cash: true,
    BankTransfer: true,
  },
  "PaymentMethodCZ"
);

export type PaymentMethodCZ = t.TypeOf<typeof PaymentMethodCZ>;

export const PaymentMethod = t.union(
  [PaymentMethodCZ, PaymentMethodSK],
  "PaymentMethod"
);

export type PaymentMethod = t.TypeOf<typeof PaymentMethod>;

/**
 * TypeOfRent
 */
export const TypeOfRent = t.keyof(
  {
    RentFromOtherRealEstate: true,
    RentFromPledgedRealEstate: true,
  },
  "TypeOfRent"
);

export type TypeOfRent = t.TypeOf<typeof TypeOfRent>;

export const JobPosition = t.type(
  {
    id: NonNegativeInteger,
    key: NonEmptyString,
    tenant: Tenant,
    language: NonEmptyString,
    name: NonEmptyString,
  },
  "JobPosition"
);

export type JobPosition = t.TypeOf<typeof JobPosition>;

export const FreelancerType = t.type(
  {
    id: NonNegativeInteger,
    key: NonEmptyString,
    tenant: Tenant,
    language: NonEmptyString,
    name: NonEmptyString,
  },
  "FreelancerType"
);

export type FreelancerType = t.TypeOf<typeof FreelancerType>;

export const BusinessSector = t.type(
  {
    id: NonNegativeInteger,
    key: NonEmptyString,
    tenant: Tenant,
    language: NonEmptyString,
    name: NonEmptyString,
  },
  "BusinessSector"
);

export type BusinessSector = t.TypeOf<typeof BusinessSector>;

export const EmploymentType = t.keyof(
  {
    IndefiniteContract: true,
    LimitedContract: true,
    Agreement: true,
    SeasonalContract: true,
    Other: true,
  },
  "EmploymentType"
);

export type EmploymentType = t.TypeOf<typeof EmploymentType>;

/**
 * SpecialTypeIncome
 */
export const SpecialTypeOfIncomeCZ = t.keyof({
  Alimony: true,
  AllowanceForSoldiers: true,
  HousingAllowanceForSoldiers: true,
  HomeDuties: true,
  MaternityLeave: true,
  NonStandard: true,
  Pensioner: true,
  Student: true,
  Unemployed: true,
});

export type SpecialTypeOfIncomeCZ = t.TypeOf<typeof SpecialTypeOfIncomeCZ>;

export const SpecialTypeOfIncomeSK = t.keyof({
  AllowanceForSoldiers: true,
  HousingAllowanceForSoldiers: true,
  HomeDuties: true,
  IncomeFromCapitalAssets: true,
  NonStandard: true,
  Pensioner: true,
  RentForProperty: true,
  Unemployed: true,
});

export type SpecialTypeOfIncomeSK = t.TypeOf<typeof SpecialTypeOfIncomeSK>;

export const SpecialTypeOfIncome = t.union(
  [SpecialTypeOfIncomeCZ, SpecialTypeOfIncomeSK],
  "SpecialTypeOfIncome"
);

export type SpecialTypeOfIncome = t.TypeOf<typeof SpecialTypeOfIncome>;

export const AllowanceType = t.keyof({
  Parental: true,
  FutureParental: true,
});

export type AllowanceType = t.TypeOf<typeof AllowanceType>;

export const PensionTypeCZ = t.keyof({
  Disability: true,
  OldAge: true,
  Orphans: true,
  Widower: true,
  Retirement: true,
});

export type PensionTypeCZ = t.TypeOf<typeof PensionTypeCZ>;

export const PensionTypeSK = t.keyof({
  OldAge: true,
  Widower: true,
  FullInvalidityPension: true,
  ParentalAllowance: true,
  RetirementAnnuity: true,
  OtherPensionOrAllowance: true,
  Bursary: true,
  TravelCompensationDiets: true,
});

export type PensionTypeSK = t.TypeOf<typeof PensionTypeSK>;

export const PensionType = t.union(
  [PensionTypeCZ, PensionTypeSK],
  "PensionType"
);

export type PensionType = t.TypeOf<typeof PensionType>;

export const RetirementRentType = t.keyof({
  Policeman: true,
  FireRescueCorps: true,
  CustomAdministration: true,
  PrisonService: true,
  GeneralInspectorate: true,
  SecurityInformationService: true,
  OfficeForForeignRelations: true,
  FormerArmyMembers: true,
  Other: true,
});

export type RetirementRentType = t.TypeOf<typeof RetirementRentType>;

export const ThirdParty = t.type(
  {
    id: NonNegativeInteger,
    tenant: Tenant,
    name: NonEmptyString,
    ico: optionFromUndefined(t.string),
    ndg: optionFromUndefined(t.string),
  },
  "ThirdParty"
);

export type ThirdParty = t.TypeOf<typeof ThirdParty>;

/**
 * IncomeSimplified
 */
export const IncomeSimplified = t.type(
  {
    simplifiedVersion: t.literal(true),
    totalAvgMonthlyIncome: t.number,
  },
  "IncomeSimplified"
);

export type IncomeSimplified = t.TypeOf<typeof IncomeSimplified>;

export function isIncomeSimplified(
  income: GetIncome
): income is GetIncomeSimplified {
  return income.simplifiedVersion;
}

export const GetIncomeSimplified = t.intersection(
  [IncomeSimplified, optional({ completed: t.boolean })],
  "GetIncomeSimplified"
);

export type GetIncomeSimplified = t.TypeOf<typeof GetIncomeSimplified>;

export const IncomeFull = t.type(
  {
    companyInfo: optional(
      {
        applicantsShareHigherThan33: t.boolean,
        businessSector: NonEmptyString,
        businessStartingDate: MonthYear,
        companyIco: t.string,
        companyName: t.string,
        addIco: t.boolean,
        nameOfEntrepreneur: t.string,
        entrepreneurIco: t.string,
        country: t.string,
        city: t.string,
        street: t.string,
        houseNr: t.string,
        zipCode: t.string,
        phone: t.string,
        equityPositive: t.boolean,
        freelancerType: NonEmptyString,
        nameOf3P: t.string,
        ico3P: t.string,
        stakeInCompany: t.number,
      },
      "CompanyInfo"
    ),
    contractInfo: optional(
      {
        contractIndefinitePeriod: t.boolean,
        contractPeriodExpiration: MonthYear,
        employedInSpecificProfessions: t.boolean,
        employmentType: EmploymentType,
        inProbation: t.boolean,
        jobPosition: NonEmptyString,
        lengthOfUninterruptedCurrentEmployment: t.number,
        lengthOfUninterruptedOverallEmployment: t.number,
        paymentMethod: PaymentMethod,
        paymentMethodLabel: t.string,
        startingDate: MonthYear,
        workBasedOnAgreementOnWorkingActivity: t.boolean,
      },
      "ContractInfo"
    ),
    incomeInfo: t.intersection(
      [
        t.type({
          incomeSource: IncomeSource,
        }),
        optional({
          alimonyChildrenInvolved: t.number,
          allowanceType: AllowanceType,
          amortizationOfRentedRe: t.number,
          annualGrossIncome: t.number,
          bruttoComissions: t.number,
          businessGrossIncome: t.number,
          employedInCompany: t.boolean,
          equityFromLastYear: t.number,
          grossIncomes: t.number,
          incomeAsDeductibleExpenses: t.boolean,
          incomeDescription: t.string,
          incomeFromRentContract: t.number,
          incomeSourceLabel: t.string,
          isAlimonyIdentifiable: t.boolean,
          isAlimonyOutsideCountry: t.boolean,
          isApplicantDeclareAsUserInDeclaration: t.boolean,
          isCooperativeOwnership: t.boolean,
          keepsAccountancy: t.boolean,
          last3YearsPaidSharedProfit: t.number,
          lastPeriodProfit: t.number,
          monthlyIncome: t.number,
          negativeBusinessProfit: t.boolean,
          pensionType: PensionType,
          profitSharingIncome: t.boolean,
          r101BruttoIncomes: t.number,
          r201IncomeFromLease: t.number,
          r37PartialTaxBase: t.number,
          r38PartialTaxBase: t.number,
          r39PartialTaxBase: t.number,
          // r74TaxAfterClaimedRelief: t.number,
          reCoOwned: t.boolean,
          reInPersonalOwnership: t.boolean,
          reUsedAsCollateral: t.boolean,
          // retirementRentType: RetirementRentType,
          salaryCurrency: t.string,
          specialTypeOfIncome: SpecialTypeOfIncome,
          tax: t.number,
          taxBase: t.number,
          typeOfRent: TypeOfRent,
          youngPeopleConditions: t.boolean,
          taxAdvisor: t.boolean,
          calculationBasedOnTurnover: t.boolean,
          profitLastPeriodPositive: t.boolean,
        }),
      ],
      "IncomeInfo"
    ),
  },
  "IncomeFull"
);

export type IncomeFull = t.TypeOf<typeof IncomeFull>;

/**
 * MainIncome
 */
export const MainIncome = t.intersection(
  [IncomeFull, t.type({ simplifiedVersion: t.literal(false) })],
  "MainIncome"
);

export type MainIncome = t.TypeOf<typeof MainIncome>;

/**
 * AdditionalIncome
 */
export const AdditionalIncome = t.intersection(
  [
    IncomeFull,
    t.type({
      recordId: UUID,
    }),
  ],
  "AdditionalIncome"
);

export type AdditionalIncome = t.TypeOf<typeof AdditionalIncome>;

/**
 * GetIncomeFull
 */
export const GetIncomeFull = t.intersection(
  [
    MainIncome,
    optional({
      additionalIncomes: t.array(AdditionalIncome),
      completed: t.boolean,
    }),
  ],
  "GetIncomeFull"
);

export type GetIncomeFull = t.TypeOf<typeof GetIncomeFull>;

export function isIncomeFull(income: GetIncome): income is GetIncomeFull {
  return !income.simplifiedVersion;
}

/**
 * AddIncomeFull
 */
export const SaveIncomeFull = t.intersection(
  [
    MainIncome,
    t.partial({
      additionalIncomes: t.array(IncomeFull),
    }),
  ],
  "SaveIncomeFull"
);

export type SaveIncomeFull = t.TypeOf<typeof SaveIncomeFull>;

/**
 * GetIncome
 */
export const GetIncome = t.union(
  [GetIncomeFull, GetIncomeSimplified],
  "GetIncome"
);

export type GetIncome = t.TypeOf<typeof GetIncome>;

/**
 * SaveIncome
 */
export const SaveIncome = t.union(
  [SaveIncomeFull, IncomeSimplified],
  "SaveIncome"
);

export type SaveIncome = t.TypeOf<typeof SaveIncome>;

/**
 * DeleteAdditionalIncome
 */
export const DeleteAdditionalIncome = t.type(
  {
    recordId: UUID,
  },
  "DeleteAdditionalIncome"
);

export type DeleteAdditionalIncome = t.TypeOf<typeof DeleteAdditionalIncome>;
