import { Login } from "./Login";
import { LoginLandingWrapper } from "./LoginLandingWrapper";
import { ComponentProps, useState } from "react";
import { LocalizedString, Snackbar, Space } from "design-system";
import { Option } from "fp-ts/Option";
import { constant, constFalse, constTrue, pipe } from "fp-ts/function";
import { option } from "fp-ts";

import { PortalStatusAlert } from "../Common/PortalStatusAlert/PortalStatusAlert";
import { usePortalStatusContext } from "../PortalStatusContext";
import { foldPortalStatus } from "../globalDomain";

type Props = ComponentProps<typeof Login> & {
  successMessage: Option<LocalizedString>;
};

export function LoginLanding(props: Props) {
  const [displaySnackbar, setDisplaySnackbar] = useState(true);
  const { portalStatus } = usePortalStatusContext();

  const disabled = foldPortalStatus(portalStatus, {
    onInitial: constFalse,
    onNone: constFalse,
    onActive: constTrue,
    onScheduled: constFalse,
  });

  return (
    <LoginLandingWrapper>
      {foldPortalStatus(portalStatus, {
        onInitial: () => <Space units={20} />,
        onNone: () => <Space units={20} />,
        onActive: () => (
          <>
            <PortalStatusAlert inAuthenticatedPage={false} />
            <Space units={15} />
          </>
        ),
        onScheduled: () => (
          <>
            <PortalStatusAlert inAuthenticatedPage={false} />
            <Space units={15} />
          </>
        ),
      })}

      <Login
        onLogin={props.onLogin}
        onThirdPartyPasswordSetup={props.onThirdPartyPasswordSetup}
        disabled={disabled}
      />
      {pipe(
        props.successMessage,
        option.filter(constant(displaySnackbar)),
        option.map(message => (
          <Snackbar
            action={option.none}
            type="success"
            message={message}
            onClose={() => setDisplaySnackbar(false)}
          />
        )),
        option.toNullable
      )}
    </LoginLandingWrapper>
  );
}
